import {Content, LoadingModalContainer} from './styles';

import Logo from "../../assets/img/logo-mini.png";
import LogoBlack from "../../assets/img/logo-crowd-mini-black.png";

export const Loading = () => {
    return (
        <Content>
           <div className="image pulse">
               <img src={Logo} alt="" />
           </div>
        </Content>
    )
}
export const LoadingModal = () => {
    return (
        <LoadingModalContainer>
            <div className="image animated rotate">
                <img src={LogoBlack} alt="" />
            </div>
        </LoadingModalContainer>
    )
}
