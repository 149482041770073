import HeroImage from 'assets/img/webdoor-cut.png';
import { NavLink, Link } from 'react-router-dom';

import { Content } from './styles'

const HeroHome = () => {
    return (
        <Content>
            <div className="container">
                <div className="row flex-lg-row-reverse align-items-center justify-content-center justify-content-lg-start g-lg-5">
                    <div className="col-11 col-lg-6 ">
                        <img src={HeroImage} className="d-block mt-custom mx-auto img-fluid" width="550" alt="hero-image"
                              loading="lazy"/>
                    </div>
                    <div className="col-11 col-lg-6 text-center ">
                        <h1 className="display-3 fw-bolder lh-2 mt-5 mt-xl-0 mb-3 text-center text-lg-start">
                            Os itens do jogo agora são <span className="text-featured">ativos</span> <span className="text-featured">digitais!</span>
                        </h1>
                        <p className="lead my-4 my-lg-5 col-10 col-lg-12 mx-auto mx-lg-0 text-center text-lg-start">
                        O <strong>primeiro marketplace</strong><br /> onde transforma seus itens<br /> do jogo em ativos <strong>digitais rentáveis.</strong>
                        </p>
                        <div className="d-flex gap-4 d-md-flex justify-content-center justify-content-lg-start mt-2 my-5 my-md-4">
                            <Link to={'/explorer'} type="button" className="btn btn-outline-secondary btn-lg rounded-pill px-5">Explorar</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}

export default HeroHome
