import axios from "axios";

const api = axios.create({
    baseURL: "https://us-central1-metaexp-mobiup.cloudfunctions.net/app",
    //baseURL: "http://localhost:5001/metaexp-mobiup/us-central1/app",
    headers: {
        "Content-Type": "application/json",
    },
});

export default api;
