import DataTable from "react-data-table-component";
import styled, { keyframes } from "styled-components";

export const SalesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;


`;

export const SalesHeader = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 30px;
`;

export const SalesTitle = styled.h3`
   font-size: 30px;
   font-weight: bold;
`;

export const TotalSalesContainer = styled.div`
  width: 100%;
  /* display: flex;
  flex-direction: row; */
  /* gap: 20px;
  justify-content: space-between;
  align-items: center; */
`;

export const TotalSalesCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${({ theme }) => theme.bg200};
  border-radius: 10px;
  justify-content: space-between;
  align-items: left;
  height: 300px;
  gap: 50px;
  text-align: left;
  border-top: 4px solid orange;
`;

export const TitleSalesCard = styled.h3`
  font-size: 20px;
  font-weight: normal;
  /* text-align: center; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
`;

export const DataSalesCard = styled.p`
  font-size: 26px;
  display: flex;
  align-items: center;
  font-weight: normal;
  gap: 10px;
  /* text-align: center; */
`;

export const SalesContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.bg200};
`;

export const Table = styled.table`
  width: 100%;
  min-width: auto;
  background-color: ${({ theme }) => theme.bg200};
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10rem;
`;

export const TableRow = styled.tr``;

export const TableHeader = styled.th`
  border-bottom: 1px solid #ddd;
  padding: 35px 20px 35px 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  white-space: nowrap;
  /* text-align: left; */
`;

export const TableCell = styled.td`
  border-bottom: 1px solid #ddd;
  padding: 30px 20px 30px 20px;
  white-space: nowrap;
`;

// export const TableTest = styled(DataTable)`
//   width: 100%;
//   min-width: auto;
//   background-color: ${({ theme }) => theme.bg200};
//   padding: 15px;
//   border-radius: 8px;
//   margin-bottom: 10rem;
// `;

export const TableTest = styled(DataTable)`
  width: 100%;
  min-width: auto;
  background-color: #1e1e1e;  // Atualizado para a cor solicitada
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10rem;
`;

const rotateAnimation = keyframes`
  100% { transform: rotate(.5turn) }
`;

export const Loader = styled.div`
  width: 80px;
  height: 80px;
  display: grid;

  &::before,
  &::after {
    content: "";
    grid-area: 1/1;
    --c: radial-gradient(farthest-side, rgba(68, 0, 255, 1) 92%, #0000);
    background: 
      var(--c) 50%  0, 
      var(--c) 50%  100%, 
      var(--c) 100% 50%, 
      var(--c) 0    50%;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    animation: ${rotateAnimation} 1s infinite;
  }

  &::before {
    margin: 8px;
    filter: hue-rotate(45deg);
    background-size: 16px 16px;
    animation-timing-function: linear;
  }
`;

export const PaginationContainer = styled.tfoot`
  td {
    border: none;
    padding: 1rem 0;
  }
`;

export const PageButton = styled.button`
  background-color: transparent;
  border: 2px solid #333;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #333;
    color: #fff;
  }
`;

export const ActivePageButton = styled(PageButton)`
  background-color: #333;
  color: #fff;
`;

export const Input = styled.input`
  padding: 20px;
  margin: 1rem 0;
  border: none;
  background: #1e1e1e;
  width: 400px;
  outline: none;
  border-radius: 5px;
  color: #FFFF;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const LogoutButton = styled.button`
  padding: 10px;
  width: 100px;
  text-align: center;
  border: none;
  color: #FFF;
  background: red;
  outline: none;
  border-radius: 20px;
`;
