// styles
import { ButtonLogin, ContainerButton, Input, LoginAdminContainer, LoginAdminContent } from "./styles";

// images
import Logo from "../../assets/img/logo.png"

// contexts
import { useAuthAdmin } from "contexts/adminContext";

export const LoginAdmin = () => {
  const { email, handleInputChange, handleSubmit } = useAuthAdmin();

  return (
    <div className='container'>
      <LoginAdminContainer>
        <LoginAdminContent>
          <img src={Logo} alt="" width={300} />
          <ContainerButton onSubmit={handleSubmit}>
            <Input type="email" value={email} onChange={handleInputChange} placeholder="Digite o Email" required />
            <ButtonLogin type="submit">Acessar</ButtonLogin>
          </ContainerButton>
        </LoginAdminContent>
      </LoginAdminContainer>
    </div>
  );
}
