import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './PageTransition.scss';

const PageTransition = ({ children, location }) => (
  <TransitionGroup>
    <CSSTransition key={location.pathname} classNames='fade' timeout={300}>
      {children}
    </CSSTransition>
  </TransitionGroup>
);

export default PageTransition;
