import styled from 'styled-components';

export const Content = styled.div`
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: -webkit-center;
    
    .image img {
        height: 2em;
    }
    
    .pulse {
      animation: pulse 0.9s infinite;
      margin: 0 auto;
      display: table;
      margin-top: 50px;
      animation-direction: alternate;
      -webkit-animation-name: pulse;
      animation-name: pulse;
    }

    @-webkit-keyframes pulse {
      0% {
        -webkit-transform: scale(1);
        -webkit-filter: brightness(100%);
      }
      100% {
        -webkit-transform: scale(1.1);
        -webkit-filter: brightness(40%);
      }
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
        filter: brightness(40%);
      }
      100% {
        transform: scale(1.1);
        filter: brightness(100%);
      }
   }
`;

export const LoadingModalContainer = styled.div`
    background-color: #FFFFFF;
    width: 100%;
  
    z-index: 999999;

    margin: 1rem auto 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: -webkit-center;
    
    .image img {
        height: 3em;
    }
    .animated {
      animation-duration: 3s;
      -webkit-animation-duration: 3s;
      animation-fill-mode: none;
      -webkit-animation-fill-mode: none;
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite;
    }
@keyframes rotate {
  0% {
    /*transform: scale(1);*/
    transform-origin: center center;
    transform: rotate(-360deg) scale(1);
  }
  50% {
    /*transform: scale(1.1);*/
    transform-origin: center center;
    transform: rotate(-180deg) scale(0.6);
  }
  100% {
    /*transform: scale(1);*/
    transform-origin: center center;
    transform: rotate(0) scale(1);
  }
}
@-webkit-keyframes rotate {
  0% {
    /*-webkit-transform: scale(1);*/
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-360deg) scale(1);
  }
  50% {
    /*-webkit-transform: scale(1.1);*/
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-180deg) scale(0.1);
  }
  100% {
    /*-webkit-transform: scale(1);*/
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0) scale(1);
  }
}
.rotate {
  animation-name: rotate;
  -webkit-animation-name: rotate;
}

`;
