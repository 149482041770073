import * as Yup from "yup";

export const formSchema = Yup.object().shape({
    name: Yup.string().required("Preencha este campo"),
    // nickname: Yup.string().required("Preencha este campo"),
    //description: Yup.string().required(),
});

export const formValues = {
    name: "",
    nickname: "",
    description: "",
    birth_date: "",
    external_link: "",
    email: "",
    site: "",
    twitter: "",
    discord: "",
};

export function validateName(value) {
    const result = parseInt(value.substring(0, 1));
    if (!isNaN(result)) {
        return "Nome da coleção não pode começar com números";
    }
    if (!value.match(/^[a-zA-Z0-9\s]+$/)) {
        return "Nome da coleção não pode conter caracteres especiais";
    }
}
