import { CardContent } from './styles';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCollection } from 'web3/providers/CollectionContext';
import LogoBlack from '../../assets/img/logo-black.png'
import { formatarValorReal, getPrice } from 'utils/utils';

import { LazyLoadImage } from 'react-lazy-load-image-component';



const CardCollection = ({ collection }) => {
  const { contract, name, image, maxSupply, price, thumbnail } = collection;

  const [nfts, setNfts] = useState([]);
  const [nftsDone, setNftsDone] = useState([]);
  const [quote, setQuote] = useState();
  const { getNfts, getNftsDone } = useCollection();
  const { address } = useParams();

  useEffect(() => {
    setNfts(getNfts(contract));
    setNftsDone(getNftsDone(contract));
    setQuote(price * 3.35)
  }, [contract, getNfts]);

  const Supply = () => {
    return (
      <span className='data-supply'>
        {nftsDone?.length ? nftsDone.length : 0}/{maxSupply}
      </span>
    );
  };

  return (
    <CardContent>
      <div className='card'>
        <div className='card-image mx-3 mt-4'>
          <Link to={`/collection/${contract}`}>
            {/* <img
              src={image ? image : LogoBlack}
              className='img-fluid rounded-3 img-collection'
              alt=''
              width={400}
              height={400}
            /> */}
            <LazyLoadImage
              alt={"image"}
              src={thumbnail != "" ? thumbnail : image}
              width={300}
              height={300}
              className='img-fluid rounded-3 img-collection'
              quality={50}
            />
          </Link>
        </div>

        <div className='card-body mx-2'>
          <div className='d-flex align-items-center'>
            <h2 className='fs-5 fw-bold'>
              <Link to={`/collection/${contract}`}>{name}</Link>
            </h2>
          </div>

          <div className='d-flex mt-2 justify-content-between align-items-end'></div>

          <hr className='mt-2' />

          <div className='d-flex flex-col pt-2 justify-content-between align-items-center'>
            <div className='d-grid gap-2'>
              <Supply />
            </div>
            <div className='text-end'>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginTop: "10px", width: "100%", justifyContent: "center", alignItems: "flex-end" }}>
                <h3 className='fs-5 mb-0'>
                  {/* {price} <small>{crypto}</small> */}
                  {price} MATIC
                </h3>
                <span className="fs-6 fw-bolder text-muted">{formatarValorReal(quote)}</span>
              </div>
              {/* <Link
                to={`/collection/${contract}`}
                className='btn btn-primary btn-lg rounded-pill w-100'
              >
                Detalhes
              </Link> */}

              {/* <span className="text-primary">{ data.cost } MATIC</span> */}
              {/* <h3 className="fs-4 fw-bold mb-1">
                <span className="fs-6">MATIC {price}</span>
                <span>{quote}</span>
              </h3> */}
            </div>
          </div>
          {/* <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginTop: "10px", width: "100%", justifyContent: "center", alignItems: "flex-end" }}>
            <h3 className='fs-5 mb-0'>
              {price} <small>{crypto}</small>
              {price} MATIC
            </h3>
            <span className="fs-6 fw-bolder text-muted">{formatarValorReal(price)}</span>
          </div> */}

          <div style={{ marginTop: "25px" }}>
            <Link
              to={`/collection/${contract}`}
              className='btn btn-primary btn-lg rounded-pill w-100'
            >
              Detalhes
            </Link>
          </div>


          {/*<hr/>

              {/*<div className="d-flex mt-3 justify-content-between align-items-center">*/}
          {/*    <button*/}
          {/*        className="btn btn-primary rounded-pill w-100"*/}
          {/*        onClick={ toggle }*/}
          {/*    >*/}
          {/*        Comprar agora*/}
          {/*    </button>*/}
          {/*</div>*/}
        </div>
      </div>
    </CardContent >
  );
};
export default CardCollection;
