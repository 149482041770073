const isDark = true;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  colors: {
    default: '#474747',
    primary: '#ff66af',
    secondary: '#ffffff',
    grey300: '#424242',
    grey800: '#222222',
    grey900: '#151515',
    blue200: '#00c4ff',
    background: '#2b2b2b',
    white: '#fff',
  },
};

export const lightTheme = {
  body: '#E2E2E2',

  color: '#363537',

  border: '#d9d9d9',

  bg: '#E2E2E2',
  bg100: '#e5e5e5',
  bg200: '#e9e9e9',
  bg300: '#d5d5d5',
  bg400: '#c9c9c9',
  bgdefault: 'rgba(68,0,255,1)',
  bgsecondary: 'rgba(19,9,51,1)',
  white: '#fff',

  inputBg: '#ffffff',
  text: '#363537',
  toggleBorder: '#FFF',
  gradient: 'linear-gradient(#39598A, #79D7ED)',
};

export const darkTheme = {
  body: '#000',

  primary: '#007cb5',
  primaryOpacity: 'rgba(0,124,181,0.84)',

  color: '#FAFAFA',

  border: '#2c2c2c',

  bg: '#000',
  bg100: '#161616',
  bg200: '#1e1e1e',
  bg300: '#222222',
  bg400: '#565656',
  bgdefault: 'rgba(68,0,255,1)',
  bgsecondary: 'rgba(19,9,51,1)',
  white: '#fff',

  inputBg: '#222222',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  gradient: 'linear-gradient(#091236, #1E215D)',
};

export const theme = isDark ? darkTheme : lightTheme;