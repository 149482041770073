import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { query, collection, where, getDocs } from 'firebase/firestore';
import { db } from "firebaseConfig";

export const AdminContext = createContext();

export const useAuthAdmin = () => {
  return useContext(AdminContext);
}

export const AdminContextProvider = ({ children }) => {
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin') === 'true');
  const navigate = useNavigate();

  useEffect(() => {
    setIsAdmin(localStorage.getItem('isAdmin') === 'true');
  }, []);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const usersQuery = query(collection(db, process.env.REACT_APP_ENVIRONMENT + '_users'), where('email', '==', email));
    const snapshot = await getDocs(usersQuery);

    if (snapshot.empty) {
      alert('Usuário não encontrado.');
      setIsAdmin(false);
      return;
    }

    const user = snapshot.docs[0]?.data();
    if (user?.isAdmin) {
      localStorage.setItem('isAdmin', 'true');
      setIsAdmin(true);
      navigate('/sales');
    } else {
      alert('Não é um usuário admin.');
      setIsAdmin(false);
    }
  };

  const logout = () => {
    setIsAdmin(false);
    localStorage.removeItem('isAdmin');
    navigate('/login-admin');
  }

  const contextValue = {
    email,
    setEmail,
    handleInputChange,
    handleSubmit,
    isAdmin,
    logout
  };

  return (
    <AdminContext.Provider value={contextValue}>
      {children}
    </AdminContext.Provider>
  );
}

export default AdminContextProvider;

