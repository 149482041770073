import styled from 'styled-components';

export const CardContent = styled.div`
    .card {
        background-color: ${({theme}) => theme.bg200};
        border-radius: 20px;
        padding-bottom: 25px;
    }
    
    .card-image {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    
    .creator-content {
        margin-top: 3.5rem;
    }
          
    a {
        text-decoration: none;
        color: ${({theme}) => theme.color};
    }
    
    .creator  {
        border-radius: 10px;
        overflow: hidden;
    }
    
    .creator img {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        object-fit: cover;
        width: 5em;
        height: 5em;
        border: 5px solid ${({theme}) => theme.bg200};
    }

    img{
        transition: all .5s ease;
    }

    img:hover{
        transform: scale(1.1);
    }

    
`;
