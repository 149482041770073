import React, { createContext, useContext, useEffect, useState } from 'react';

import { Loading } from 'components/Loading';
import { networkParams } from '../networks/networks';
import {
  faBorderAll,
  faCarSide,
  faHouseChimney,
} from '@fortawesome/free-solid-svg-icons';
import { getAll } from 'services/firestore.service';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { useApp } from './AppContext';

export const CollectionContext = createContext(0);

export const CollectionProvider = (props) => {
  const { wallet } = useApp();
  const [loading, setLoading] = useState(true);
  const [collections, setCollections] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [nftsDone, setNftsDone] = useState([]);
  const [categories, setCategories] = useState([]);

  const crypto = networkParams[collections?.network]?.crypto;

  const getCollection = useCallback(
    (collectionAddress) => {
      return collections.find((c) => c.contract === collectionAddress);
    },
    [collections]
  );

  const getNfts = useCallback(
    (contractAddress) => {
      return nfts.filter((nft) => nft.contractAddress === contractAddress);
    },
    [nfts]
  );

  const getNftsDone = useCallback(
    (contractAddress) => {
      return nfts.filter((nft) => nft.contractAddress === contractAddress && nft.walletAddress != "");
    },
    [nftsDone]
  );

  const getNft = useCallback(
    (nftId) => {
      return nfts.find((nft) => nft.id === nftId);
    },
    [nfts]
  );

  const getUserNfts = useCallback(() => {
    return nfts.filter((nft) => nft.walletAddress === wallet);
  }, [nfts, wallet]);

  useEffect(() => {
    const request = async () => {
      setLoading(true);
      Promise.all([getAll('categories'), getAll('collections'), getAll('nfts')])
        .then((responses) => {
          //Categorias
          const categories = responses[0]
            .map((c) => {
              switch (c.key) {
                case 'car':
                  c.icon = faBorderAll;
                  break;
                case 'mansion':
                  c.icon = faBorderAll;
                  break;
                case 'other':
                  c.icon = faBorderAll;
                  break;
                default:
                  c.icon = faBorderAll;
                  console.log('Categoria não mapeada!!');
                  break;
              }
              return c;
            })
            .sort((a, b) => a.priority - b.priority);
          setCategories(categories);

          //Coleções
          const collections = (responses[1] || [])
            .map((currentCollection) => {
              currentCollection.contractAddress = currentCollection.contract;
              currentCollection.network = currentCollection.chainId;
              currentCollection.category = categories.find(
                (c) => c.key === currentCollection.category
              );
              //currentCollection.category = currentCollection.category;
              currentCollection.formattedDate = format(
                currentCollection.createdAt
                  ? currentCollection.createdAt.toDate()
                  : new Date(),
                'MMM yyyy'
              );
              return currentCollection;
            })
            .sort(
              (a, b) =>
                (b.createdAt ? b.createdAt : 0) -
                (a.createdAt ? a.createdAt : 0)
            );
          console.log("collections -> ", collections);
          setCollections(collections);

          //NFT's
          const nfts = responses[2]
            .map((nft) => {
              const collectionNFT = collections.find(
                (c) => c.contract === nft.contractAddress
              );
              //console.log("collectionNFT -> ", collectionNFT);
              nft.price = collectionNFT?.price;
              nft.collectionId = collectionNFT?.id;
              nft.contract = collectionNFT?.contract;
              nft.network = nft?.chainId;
              return nft;
            })
            .sort(
              (a, b) =>
                (b.createdAt ? b.createdAt : 0) -
                (a.createdAt ? a.createdAt : 0)
            );
          console.log("nfts -> ", nfts);
          setNfts(nfts);
          setNftsDone(nfts);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log('Error ao carregar dados: ', error);
        });
    };
    request();
  }, []);

  useEffect(() => {
    getUserNfts();
    console.log("hook");
  }, [nfts])

  if (loading) return <Loading />;

  return (
    <CollectionContext.Provider
      value={{
        crypto,
        collections: collections, //todas coleções existentes
        categories: categories, //todas categorias existentes (mapeadas com icons)
        getCollection: getCollection, //obter coleção (por contract)
        getNfts: getNfts, //obter nfts de uma coleção específica (por contractAddress)
        getNft: getNft, //obter nft específico (pelo id)
        getNftsDone: getNftsDone, //obter nfts de uma coleção específica (por contractAddress)
        getUserNfts: getUserNfts, //obter nfts do usuário (pela sua wallet)
      }}
    >
      {props.children}
    </CollectionContext.Provider>
  );
};

export const useCollection = () => useContext(CollectionContext);
