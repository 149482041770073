import { DataSalesCard, Input, Loader, LogoutButton, SalesContainer, SalesHeader, SalesTitle, TitleSalesCard, TotalSalesCard, TotalSalesContainer } from "./styles"
import SalesIcon from "../../assets/img/sales.png"
import { useEffect } from "react"
import { sales } from "services/pix.service"
import { useState } from "react"
import PixIcon from "../../assets/img/pix.png"
import Marketplace from "../../assets/img/marketplace.png"
import Game from "../../assets/img/game.png"
import Nft from "../../assets/img/nft.png"
import Credit from "../../assets/img/credit.png"
import Polygon from "../../assets/img/polygon.png"
import { formatarValorReal } from "utils/utils";
import DataTable from "react-data-table-component"
import { useAuthAdmin } from "contexts/adminContext"

const customStyles = {
  table: {
    style: {
      padding: '20px',
      backgroundColor: '#1e1e1e',
      borderRadius: '8px',
      overflow: 'hidden',
    },
  },
  headCells: {
    style: {
      fontSize: '1rem',
      backgroundColor: '#1e1e1e',
      color: 'white',
      padding: '10px 20px',
    },
  },
  rows: {
    style: {
      fontSize: '1rem',
      backgroundColor: '#1e1e1e',
      borderColor: '#333',
      color: 'white',
      padding: '10px 0',
    },
    hoverStyle: {},
  },
  cells: {
    style: {
      backgroundColor: '#1e1e1e',
      color: 'white',
      padding: '10px 20px',
    },
  },
  pagination: {
    style: {
      backgroundColor: '#1e1e1e',
      color: '#ffffff !important',
      padding: '20px',
      borderRadius: '10px',
      marginTop: '10px',
    },
    pageButtonsStyle: {
      color: '#ffffff',
      borderColor: '#ffffff',
      borderRadius: '10px',
      backgroundColor: '#ffffff',
      hoverBackgroundColor: '#ffffff',
      hoverBorderColor: '#ffffff',
      activeBackgroundColor: 'transparent',
      activeBorderColor: 'transparent',
      marginRight: '5px',
      marginLeft: '5px',
    },
    hoverStyle: {}
  },
};


export const Sales = () => {
  const columns = [
    { name: "Coleção", selector: "collection", sortable: true },
    { name: "Preço", selector: "price", center: true, sortable: true, format: row => `${row.price} MATIC` },
    { name: "Quantidade", selector: "count", center: true, sortable: true },
    { name: "Game", selector: "salesMarketPlace", center: true, sortable: true, format: row => row.salesMarketPlace.toFixed(3) },
    { name: "Pix", selector: "salesMarketPlacePix", center: true, sortable: true, format: row => row.salesMarketPlacePix.toFixed(3) },
    { name: "Cartão", selector: "salesMarketPlaceCartaoCredito", center: true, sortable: true, format: row => row.salesMarketPlaceCartaoCredito.toFixed(3) },
    { name: "Cripto", selector: "salesMarketPlaceCripto", center: true, right: true, sortable: true, format: row => row.salesMarketPlaceCripto.toFixed(3) }
  ];
  const [data, setData] = useState({ total: {}, contracts: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");

  const { logout } = useAuthAdmin();

  const chainId = process.env.REACT_APP_CHAIN;
  //const chainId = 137;

  const getSales = async () => {
    setIsLoading(true);
    const response = await sales({ chainId });
    setData(response);
    setIsLoading(false);
  };

  const filteredData = Object.values(data.contracts).filter(item =>
    item.collection.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    getSales();
  }, []);

  return (
    <div className='container'>
      <SalesContainer>
        <div style={{ display: "flex", alignItems: "end", justifyContent: "right" }}>
          <LogoutButton onClick={logout} style={{ marginTop: '10px' }}>Sair</LogoutButton>
        </div>
        <SalesHeader className="d-flex align-items-end">
          <SalesTitle>Total de vendas</SalesTitle>
          <img src={SalesIcon} alt="sales" width={70} />
        </SalesHeader>

        <TotalSalesContainer className="row">
          <div className="col-12 col-md-3">
            <TotalSalesCard>
              {isLoading ? (
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5rem", marginBottom: "5rem" }}>
                  <Loader />
                </div>
              ) : (
                <>
                  <div style={{ display: "flex", background: "#565656", padding: "10px", width: "65px", alignItems: "center", justifyContent: "center", borderRadius: "5px" }}>
                    <img src={PixIcon} alt="" width={40} />
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <TitleSalesCard>Compras com PIX</TitleSalesCard>
                    <DataSalesCard>{data.total.pix.toFixed(3)}
                      <img src={Polygon} alt="" width={30} />
                    </DataSalesCard>
                  </div>
                </>
              )}
            </TotalSalesCard>
          </div>
          <div className="col-12 col-md-3 mt-3 mt-md-0">
            <TotalSalesCard>
              {isLoading ? (
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5rem", marginBottom: "5rem" }}>
                  <Loader />
                </div>
              ) : (
                <>
                  <div style={{ display: "flex", background: "#565656", padding: "10px", width: "65px", alignItems: "center", justifyContent: "center", borderRadius: "5px" }}>
                    <img src={Marketplace} alt="" width={40} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <TitleSalesCard>Compras com MATIC</TitleSalesCard>
                    <DataSalesCard>{data.total.criptoMarketPlace.toFixed(3)}
                      <img src={Polygon} alt="" width={30} /></DataSalesCard>
                  </div>
                </>
              )}

            </TotalSalesCard>
          </div>
          <div className="col-12 col-md-3 mt-3 mt-md-0">
            <TotalSalesCard>
              {isLoading ? (
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5rem", marginBottom: "5rem" }}>
                  <Loader />
                </div>
              ) : (
                <>
                  <div style={{ display: "flex", background: "#565656", padding: "10px", width: "65px", alignItems: "center", justifyContent: "center", borderRadius: "5px" }}>
                    <img src={Game} alt="" width={40} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <TitleSalesCard>Compras no Game</TitleSalesCard>
                    <DataSalesCard>{data.total.game.toFixed(3)}
                      <img src={Polygon} alt="" width={40} />
                    </DataSalesCard>
                  </div>
                </>
              )}
            </TotalSalesCard>
          </div>
          <div className="col-12 col-md-3 mt-3 mt-md-0">
            <TotalSalesCard>
              {isLoading ? (
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5rem", marginBottom: "5rem" }}>
                  <Loader />
                </div>
              ) : (
                <>
                  <div style={{ display: "flex", background: "#565656", padding: "10px", width: "65px", alignItems: "center", justifyContent: "center", borderRadius: "5px" }}>
                    <img src={Credit} alt="" width={40} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <TitleSalesCard>Compras com Cartão</TitleSalesCard>
                    <DataSalesCard>{data.total.mercado_pago.toFixed(3)}
                      <img src={Polygon} alt="" width={40} />
                    </DataSalesCard>
                  </div>
                </>
              )}
            </TotalSalesCard>
          </div>
        </TotalSalesContainer>


        <SalesHeader className="mt-5">
          <SalesTitle>Vendas por coleção</SalesTitle>
          <img src={Nft} alt="sales" width={70} />
        </SalesHeader>

        {isLoading ? (
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5rem", marginBottom: "5rem", background: "#1e1e1e", padding: "40px", borderRadius: "10px" }}>
            <Loader />
          </div>
        ) : (
          <div style={{ marginBottom: "50px" }}>
            <Input
              type="text"
              placeholder="Buscar coleção..."
              value={filterText}
              onChange={e => setFilterText(e.target.value)}
            />
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              responsive
              defaultSortField="collection"
              sortIcon={<span className="sort-icon" />}
              highlightOnHover
              customStyles={customStyles}

            />
          </div>
        )}
      </SalesContainer>
    </div >
  );
};
