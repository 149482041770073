import api from "./api";

export const createRfq = (data) => {
    return api.post('/createOrder', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const updateRfq = (data) => {
    return api.post('/createOrder', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const createRfqCrypto = (data) => {
    return api.post('/createOrderCrypto', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const generateEmailCode = (data) => {
    return api.post('/generateEmailCode', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const checkEmailCode = (data) => {
    return api.post('/checkEmailCode', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const createInvoice = (data) => {
    return api.post('/createPix', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const checkPixStatus = (data) => {
    return api.post('/checkPixStatus', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const webhookMetaexp = (data) => {
    return api.post('/webhook/metaexp', data)
    .then(response => response.data)
    .catch(error => console.log(error));
}

export const sales = (data) => {
    return api.post('/sales', data)
    .then(response => response.data)
    .catch(error => console.log(error));
}

// export const mercadoPago = (data) => {
//     return api.post('/mercadopago/invoice', data)
//     .then(response => response.data)
//     .catch(error => console.log(error));
// }

export const mercadoPago = (data) => {
    return new Promise((resolve, reject) => {
    fetch("https://us-central1-metaexp-mobiup.cloudfunctions.net/app/mercadopago/invoice", {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    }).then((response) => response.json())
      .then((response) => {
        // receber o resultado do pagamento
        resolve();
      }).catch((error) => {
        // lidar com a resposta de erro ao tentar criar o pagamento
        reject();
      });
    });
}

// export const mercadoPagoCreateNft = (data) => {
//     return api.post('/mercadopago/create/nft', data)
//     .then(response => response.data)
//     .catch(error => console.log(error));
// }