import {truncateAddress} from "utils/utils";
import { useApp } from "web3/providers/AppContext";

const ButtonConnect = () => {

    const {wallet, connectWallet, disconnectWallet } = useApp()
    return (
        <>
            { !wallet ? (
                    <button className="btn btn-dark btn-connect radius-50 px-" type="button" onClick={connectWallet}>
                        <i className="fa-solid fa-wallet ms-2 fs-5"/>
                        <span>Conectar Carteira</span>
                    </button>
            ) : (
                <button className="btn btn-dark btn-connect radius-50 py-3" type="button" onClick={disconnectWallet} >
                    <i className="fa-solid fa-wallet fs-5 ms-2 me-3"/>
                    <span>{ truncateAddress(wallet) }</span>
                </button>
            )}
        </>
    );
}
export default ButtonConnect
