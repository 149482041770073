import * as S from '../styles'
import { useEffect, useState } from 'react'
import {createInvoice} from "../../../services/pix.service";
import {getGeolocation, getIpAddress, validarCPF} from "../../../utils/utils";
import { ErrorStep } from './errorStep';
import { CpfInput } from 'components/CpfInput';

export const FourthStepPix = ({buttonLabel,changeStep, invoice, setInvoice, rfqId, emailValue, closeModal, nft}) => {
  const [cpf, setCpf] = useState();
  const [invoiceApiError, setInvoiceApiError] = useState(false);
  const [isValidCpf, setIsValidCpf] = useState(false);
  const [isValidTerms, setIsValidTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [device, setDevice] = useState({});

  useEffect(() => {
    setDevice({
      ipAddress: getIpAddress(),
      port: window.location.port,
      userAgent: navigator.userAgent,
      geolocation: getGeolocation() || '',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone 
    })

  },[])

  const generateInvoice = async function() {
   
    const payload = {
      document: cpf,
      name: emailValue,
      email: emailValue,
      rfqId: rfqId.id,
      device: device,
      network: nft.network, //TODO: mockado em virturde de ser um item fixo ainda
    }

    const response = await createInvoice(payload);
    if(!response.success){
      setInvoiceApiError(true)
      return;
    }

    setInvoice(response);

    console.log(response);
    changeStep();

  }

  const handleTerms = function() {
    setIsValidTerms(!isValidTerms);
  }

  const handleSubmit = async function() {

    if (!isValidTerms) {
      setShowTerms(true);
    }

    if (!validarCPF(cpf)) {
      setIsValidCpf(true);
    }

    if (validarCPF(cpf) && isValidTerms) {
      setIsValidCpf(false);
      generateInvoice();
    } else {
      setIsValidCpf(true);
    }
  }

  if(invoiceApiError){
    return(
      <ErrorStep errorMsg={"Problema Na criação do Invoice"} buttonLabel={'Refazer compra'} changeStep={closeModal}/>
    )
  }



return(
    <S.CheckoutContainer>
      <S.EmailDetails>
      Digite o número do seu CPF no campo abaixo.
      </S.EmailDetails>

      <S.InputContainer>
        <label htmlFor="cpf">CPF</label>
        <CpfInput placeholder='Informe o seu CPF' cpf={cpf} setCpf={setCpf}/>
        {/* <input onChange={(e) => setCpf(e.target.value)}  value={cpf} /> */}
        {isValidCpf && <span className='error'>CPF inválido</span>}
        <div> <input onClick={handleTerms} type="checkbox" name="terms" id="" /> <p>Li e concordo com a <strong>política de privacidade</strong> e os <strong>termos de uso.</strong></p> </div>
        {showTerms && <span className='error'>Você precisa aceitar os termos de uso</span>}

      </S.InputContainer>

      <S.ContinueButton  onClick={() => handleSubmit()} >
        {buttonLabel}
      </S.ContinueButton>


    <S.CheckoutFooter>
      <a href='https://itspay.io/'> <strong>Powered by</strong>  <img src="https://itsramp.io/itspay_assets/img/ItsPay.png?t=1683717564" alt="" /></a>
    </S.CheckoutFooter>

    </S.CheckoutContainer>
  )

}