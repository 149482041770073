import styled from "styled-components";

export const CheckoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 20px;

    strong{
        font-size: 13px;
    }

    & > h4{
        font-size: 1.1rem;
        font-weight: bold;
        text-align: center;
    }
`

export const ContentDetails = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    div{
        display: flex;
        flex-direction: column;
    }

    p{
        font-size: 1.2rem;
        padding: 0%;
        margin: 0;
      
    }

    span{
        display: block;
        margin-bottom: 10px;
        font-size: 13px;
    }

    a{
        font-size: 11px;
        color: #000;
        text-decoration: none;
    }
`

export const ImageContainer = styled.div`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border: 2px solid #E6E6E6;
    border-radius: 10px;

    img{
        width: 138px;
    }
`

export const CheckoutDetails = styled.div`
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    width: 100%;

    div{
        display: flex;
        justify-content: space-between;
        font-size: 13.6px;
    }
`

export const CheckoutFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: -30px;

    a{
        text-decoration: none;
    }

    strong{
        font-size: 13px;
        color: #6c757d;
    }

    img{
        width: 36px ;
    }
`

export const QuantityContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    
    button{
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        font-size: 20px;
    }
`

export const ContinueButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    border-radius: 50px;
    background-color: #3697d9;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    border: none;
    margin-top: 20px;

    &:hover{
        background-color: #338dca;
    }

    transition: 0.2s ease-in-out background-color;
`

export const MyNFTSButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    padding: 20px;
    width: 100%;
    border-radius: 50px;
    background-color: #3697d9;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    border: none;
    margin-top: 20px;

    &:hover{
        background-color: #338dca;
    }

    transition: 0.2s ease-in-out background-color;
`

export const EmailDetails = styled.div`
    display: flex;
    font-size: 13px;
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 40px;

    input{
        margin-bottom: 1rem;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid #E6E6E6;
    }

    span{
        font-size: 11px;
        font-weight: bold;
        margin-top: -10px;
        margin-bottom: 40px;
    }

    div {
        display: flex;
        align-items: center;
        gap:15px;
    };

    .error{
        color: #eb4f3b;
        font-size: 12px;
        margin-bottom: 10px;
    }


`

export const AlertDetalis = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;

    p{
        text-align: center;
        
    }

    button{
        border: none;
        color: #fff;
        background-color: #2ECC71;
        padding: 10px;
        border-radius: 8px;
        margin-bottom: 20px;

        &:hover{
            background-color: #198754;
        }

        transition: 0.2s ease-in-out background-color;
    }

    .alert{
        font-size: 14px;
    }

    .plus{
        color: #fff;
    }

    .success_p{
        font-size: 1.2rem;
    }

    .success_span{
        font-size: 14px;
    }

    .check{
        color:#39ad4b;
        border: 5px solid #39ad4b;
        width: 40px;
        height: 40px;
        padding: 10px;
        border-radius: 999px;
    }

    .error{
        color: #ff0000;
   
    }
`

export const paymentDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-bottom: 40px;

    p{
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 13px;
        margin: 0;
    }

    div{
        display: flex;
        gap: 8px;
        padding: 14px;
        background-color: #ebad34; ;
        border-radius: 16px;
    }

    span{
        font-size: 13px;
    }
`

export const QrContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    p{
        font-size: 13px;
    }

    img{
        width: 220px;
        height: 220px;
    }

    span{
        display: block;
        align-self: flex-start;
        font-size: 13px;
    }

    div{
        margin-top: 20px;
        align-self: flex-start;
        display: flex;
        align-items: center;
        gap: 6px;
        width: 100%;
        border: 2px dashed #292b2c;
        padding: 8px;
        border-radius: 8px;
        background-color: #EEEEEE;
        cursor: pointer;

       

        input{
            width: 100%;
            border: none;
            background-color: #EEEEEE;
            cursor: pointer;

            &:focus{
                outline: none;
            }
        }
    }

    
`

export const PixContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;

    h3{
        font-size: 1.4rem;
        font-weight: bold;
    }
`

export const CheckStatus= styled.div`
    display: flex;
    margin-top: 28px;
    align-items: center;
    

    .default{
        border: 3px solid #b0b0b0;
        padding: 14px;
        border-radius: 999px;
        margin: 0;
    }

    .active{
        background-color: #303030;
        border: 3px solid #303030;
        color: #fff;
    }

`

export const StatusContent = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: bold;
    gap: 10px;

    margin-top: 28px;
    font-size: 1.1rem;
    color: #d4cfcf;

    .active{
        color: #474747;
    }
`

export const Separator = styled.div`
    height: 5px;
    width: 50px;
    background-color: #666565;
    margin: 0;
`
