import {useState} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {
    faTwitter,
    faYoutube,
    faInstagram,
    faDiscord,
    faTiktok
} from '@fortawesome/free-brands-svg-icons';

import Swal from "sweetalert2";
import {swalCustom} from "components/SweetAlert";
import {Link} from "react-router-dom";

import {Content} from './styles';

const NewsLetter = () => {

    const [value, setValue] = useState('');

    const sendNewsletter = () => {
        let formData = new FormData();    //formdata object

        Swal.close()

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            swalCustom.fire({
                title: 'Erro',
                icon: 'error',
                html: 'E-mail inválido',
                allowOutsideClick: true,
                timer: 4000,
            })
            return;
        }

        formData.append('data[email]', value);
        fetch(process.env.REACT_APP_SHEETDB, {
            method: 'POST',
            body: formData
        }).then(
            response => response.json()
        ).then((hml) => {
            swalCustom.fire({
                title: 'Sucesso!',
                icon: 'success',
                html: 'Seu email foi enviado para a nossa newsletter',
                allowOutsideClick: true,
                timer: 4000,
            })
        })

    };

    const SocialLink = (props) => {
        return (
            <Link target="_blank" to={props.link}>
                <div className="social-link">
                    <FontAwesomeIcon icon={props.icon} className="text-white"/>
                </div>
            </Link>
        )
    }

    return (
        <Content>
            <div className="py-5">

                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-12 col-lg-6">
                            <div className="px-4 pt-5 pb-0 py-lg-5 text-center text-lg-start">

                                <h1 className="display-6 text-uppercase fw-bold" data-aos="fade-up" data-aos-delay="200">Conheça a Metaexp</h1>

                                <p className="lead mb-4" data-aos="fade-up" data-aos-delay="300">
                                    Saiba mais sobre nossas iniciativas!
                                </p>

                                <div className="justify-content-center d-none justify-content-lg-start col-12 col-lg-8 mt-5">
                                    <div className="my-4" data-aos="fade-left" data-aos-delay="200">
                                        <div className="input-group input-group-lg mb-3">
                                            <input
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                                type="text" className="form-control rounded-start-pill"
                                                placeholder="Digite seu e-mail"
                                                aria-label="Recipient's username" aria-describedby="button-addon2"
                                            />
                                            <button onClick={() => sendNewsletter()}
                                                    className="btn btn-primary rounded-end-pill" type="button"
                                                    id="button-addon2">
                                                <FontAwesomeIcon icon={faArrowRight}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 my-5">
                            <h3 className="fs-3 text-center">Junte-se à comunidade</h3>
                            <div className="d-flex mt-4 gap-2 justify-content-center">
                                <SocialLink icon={faTwitter} link="https://twitter.com/metasoulrp"/>
                                <SocialLink icon={faInstagram} link="https://instagram.com/metaexp.io"/>
                                <SocialLink icon={faDiscord} link="https://discord.com/invite/DaC3dKN4Ca"/>
                                <SocialLink icon={faYoutube} link="https://youtube.com/@MetaSoul"/>
                                <SocialLink icon={faTiktok} link="https://www.tiktok.com/@metaexp.io"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}

export default NewsLetter
