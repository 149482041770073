import GlobalStyle from './styles/globalStyle';
import Navbar from './components/Navbar';
import SideBarAccount from './components/SidebarAccount/SideBarAccount';
import AppRouter from './Router';
import Footer from './components/Footer';

import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from 'styles/theme';
import { useState } from 'react';
import { AppProvider } from 'web3/providers/AppContext';

function App() {
  const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'dark');

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      setTheme('light');
      localStorage.setItem('theme', 'light');
    }
  };

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyle />
      <Navbar toggleTheme={toggleTheme} theme={theme} />
      <SideBarAccount />
      <div className='wrapper bg-default'>
        <AppRouter />
      </div>

      <Footer />
    </ThemeProvider>
  );
}

export default App;
