"use strict";
var __spreadArrays =
    (this && this.__spreadArrays) ||
    function () {
        for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
        for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
        return r;
    };
exports.__esModule = true;
exports.NanoCollection = exports.firebaseConfig = void 0;
var rxjs_1 = require("rxjs");
var app_1 = require("firebase/app");
var operators_1 = require("rxjs/operators");
var firestore_1 = require("firebase/firestore");
exports.firebaseConfig = {
    apiKey: "AIzaSyA3to_y0xDyUk81-rzFd45eo0_ZddB6ZGQ",
    authDomain: "metaexp-mobiup.firebaseapp.com",
    projectId: "metaexp-mobiup",
    storageBucket: "metaexp-mobiup.appspot.com",
    messagingSenderId: "512966925250",
    appId: "1:512966925250:web:9661a2650afc44962bd45b",
    measurementId: "G-2QJ6BVJR9Z",
};
var app = app_1.initializeApp(exports.firebaseConfig);
/**
 * @author Guibson Martins
 * 'firebase': 9.1.0
 * 'rxjs': 6.6.0
 */
var NanoCollection = /** @class */ (function () {
    function NanoCollection(data) {
        console.log("APP Firebase = ", app);
        NanoCollection.db = firestore_1.getFirestore(app);
        Object.assign(this, data);
    }
    NanoCollection.list = function (whereQueries, orderQueries, limitNumber) {
        var _this = this;
        if (whereQueries === void 0) {
            whereQueries = [];
        }
        if (orderQueries === void 0) {
            orderQueries = [];
        }
        var q = this.createQuery(this.getCollectionRef(), whereQueries ? whereQueries : [], orderQueries ? orderQueries : []);
        return this.getQuerySnapshot(q).pipe(
            operators_1.map(function (snap) {
                return _this.fromQuerySnapshot(snap);
            }),
        );
    };
    NanoCollection.listSync = function (whereQueries, orderQueries, limitNumber) {
        var _this = this;
        if (whereQueries === void 0) {
            whereQueries = [];
        }
        if (orderQueries === void 0) {
            orderQueries = [];
        }
        var q = this.createQuery(this.getCollectionRef(), whereQueries ? whereQueries : [], orderQueries ? orderQueries : []);
        var obs = new rxjs_1.Subject();
        firestore_1.onSnapshot(q, obs);
        return obs.pipe(
            operators_1.map(function (snap) {
                return _this.fromQuerySnapshot(snap);
            }),
        );
    };
    NanoCollection.object = function (id) {
        var _this = this;
        if (typeof id !== "string" || id === "") {
            return rxjs_1.of(null);
        }
        return this.getDocumentSnapshot(id).pipe(
            operators_1.map(function (docSnap) {
                if (docSnap.exists()) {
                    return _this.fromDocumentSnapshot(docSnap);
                } else {
                    return null;
                }
            }),
        );
    };
    NanoCollection.objectSync = function (id) {
        var _this = this;
        if (typeof id !== "string" || id === "") {
            return rxjs_1.of(null);
        }
        var obs = new rxjs_1.Subject();
        firestore_1.onSnapshot(this.getDocRef(id), obs);
        return obs.pipe(operators_1.map(this.fromDocumentSnapshot)).pipe(
            operators_1.map(function (item) {
                return new _this(item);
            }),
        );
    };
    NanoCollection.add = function (obj) {
        if (obj === null || obj === undefined) {
            return rxjs_1.of(null);
        }
        obj.createdAt = new Date();
        return rxjs_1.from(firestore_1.addDoc(this.getCollectionRef(), this.cleanObject(obj))).pipe(
            operators_1.map(function (docRef) {
                return docRef.id;
            }),
        );
    };
    NanoCollection.remove = function (id) {
        if (typeof id !== "string" || id === "") {
            return rxjs_1.of(null);
        }
        return rxjs_1.from(firestore_1.deleteDoc(this.getDocRef(id)));
    };
    /**
     * Para atualizar alguns campos de um documento sem sobrescrever o documento inteiro
     */
    NanoCollection.update = function (id, obj) {
        if (typeof id !== "string" || id === "" || obj === null || obj === undefined || Object.keys(obj).length === 0) {
            return rxjs_1.of(null);
        }
        obj.updatedAt = new Date();
        var docRef = this.getDocRef(id);
        return rxjs_1.from(firestore_1.updateDoc(docRef, this.cleanObject(obj)));
    };
    /**
     * Para criar ou sobrescrever um único documento
     * Se o documento não existir, ele será criado.
     * Se o documento existir, seu conteúdo será substituído pelos novos dados fornecidos.
     */
    NanoCollection.set = function (id, obj) {
        if (typeof id !== "string" || id === "" || obj === null || obj === undefined || Object.keys(obj).length === 0) {
            return rxjs_1.of(null);
        }
        var docRef = this.getDocRef(id);
        return rxjs_1.from(firestore_1.setDoc(docRef, this.cleanObject(obj)));
    };
    NanoCollection.cleanObject = function (obj) {
        var _this = this;
        obj = Object.assign({}, obj);
        Object.keys(obj).forEach(function (key) {
            return (obj[key] && typeof obj[key] === "object" && _this.cleanObject(obj[key])) || (obj[key] === undefined && delete obj[key]);
        });
        return obj;
    };
    NanoCollection.createQuery = function (_collection, wheres, orders) {
        if (wheres === void 0) {
            wheres = [];
        }
        if (orders === void 0) {
            orders = [];
        }
        return firestore_1.query.apply(
            void 0,
            __spreadArrays(
                [_collection],
                wheres.map(function (w) {
                    return firestore_1.where(w.name, w.operator, w.value);
                }),
                orders.map(function (o) {
                    return firestore_1.orderBy(o.name, o.type ? o.type : "desc");
                }),
            ),
        );
    };
    NanoCollection.getDocumentSnapshot = function (id) {
        return rxjs_1.from(firestore_1.getDoc(this.getDocRef(id)));
    };
    NanoCollection.getQuerySnapshot = function (q) {
        if (q) {
            return rxjs_1.from(firestore_1.getDocs(q));
        }
        return rxjs_1.from(firestore_1.getDocs(this.getCollectionRef()));
    };
    NanoCollection.getDocRef = function (id) {
        return firestore_1.doc(NanoCollection.db, this.path, id);
    };
    NanoCollection.getCollectionRef = function () {
        return firestore_1.collection(NanoCollection.db, this.path);
    };
    NanoCollection.fromDocumentSnapshot = function (document) {
        if (!document.exists) {
            return null;
        }
        var obj = document.data();
        if (!obj) {
            return null;
        }
        obj.id = document.id;
        return obj;
    };
    NanoCollection.fromQuerySnapshot = function (localQuery) {
        return localQuery.docs.map(function (d) {
            if (!d.exists) {
                return null;
            }
            var obj = d.data();
            obj.id = d.id;
            return obj;
        });
    };
    NanoCollection.db = firestore_1.getFirestore();
    return NanoCollection;
})();
exports.NanoCollection = NanoCollection;
