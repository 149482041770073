import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ModalApp } from 'components/Modal';
import $ from 'jquery';
import { useApp } from 'web3/providers/AppContext';
import { pinata } from 'services/contract.service';
import { AddTokenMetamask, MintCollection, readContract, switchNetwork } from 'web3/contracts/functions';
import { abi } from 'utils/abi';
import { NFT } from 'models/nft';
import ModalDefault from 'components/ModalDefault/ModalDefault';
import { CheckoutPix } from "components/Checkout";
import { webhookMetaexp, mercadoPago, mercadoPagoCreateNft } from "../../services/pix.service";
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react';

export const ButtonMint = ({
  children = 'Comprar',
  amount,
  priceBRL,
  priceMP,
  nft,
  collection,
  crypto,
  disabled,
  success,
  promo,
  priceScreen
}) => {
  const [loading, setLoading]                                  = useState(null);
  const [alertModal, setAlertModal]                            = useState(null);
  const [modal, setModal]                                      = useState(false);
  const { signer, wallet, connectWallet, connection, balance } = useApp();
  const { contract, price, chainId }                           = collection;
  const location                                               = useLocation();
  const [openPixNFTModal, setOpenPixNFTModal]                  = useState(false);
  const [openMercadoPagoNFTModal, setOpenMercadoPagoNFTModal]  = useState(false);
  const [showCheckout, setShowCheckout]                        = useState(false);
  const [countRender, setCountRender]                          = useState(false);
  const [actuallyLabel, setActuallyLabel]                      = useState('Comprar Nft');
  const [url, setUrl]                                          = useState(window.location.href);

  const toggle = () => setModal(!modal);

  const handleCloseModals = () => {
    setAlertModal(false);
    setOpenPixNFTModal(false);
    setOpenMercadoPagoNFTModal(false);
  }

  function handleCloseCheckout() {
    setShowCheckout(false)
  }

  const handleOpenPixModalNFT = () => {
    setOpenPixNFTModal(true);
    setModal(false);
  }

  const handleOpenMercadoPagoModalNFT = async () => {
    let provider = signer;

    let deployConnection = connection;
    let walletDeploy = wallet;
    let providerDeploy = provider;
    let signerDeploy = signer;
    
    if (!wallet) {
      setLoading(null);
      setModal(false);
      const connected = await connectWallet();
      if (typeof connected === 'undefined') {
        return setAlertModal({
          type: 'error',
          title: 'Oops!',
          description: 'Você precisa se conectar',
        });
      }
      deployConnection = connected.connection;
    }

    //await switchNetwork(deployConnection, process.env.REACT_APP_CHAIN);

    setOpenMercadoPagoNFTModal(true);
    setModal(false);
  }

  const mint = async () => {
    let provider = signer;

    let deployConnection = connection;
    let walletDeploy = wallet;
    let providerDeploy = provider;
    let signerDeploy = signer;
    
    if (!wallet) {
      setLoading(null);
      setModal(false);
      const connected = await connectWallet();
      if (typeof connected === 'undefined') {
        return setAlertModal({
          type: 'error',
          title: 'Oops!',
          description: 'Você precisa se conectar',
        });
      }
      deployConnection = connected.connection;
      walletDeploy = connected.address;
      providerDeploy = connected.provider;
      signerDeploy = connected.signer;
    }

    await switchNetwork(deployConnection, process.env.REACT_APP_CHAIN);  

    setLoading({
      title: 'Aguarde',
      description: 'Estamos processando as informações do contrato',
    });

    const contractForMint = readContract(signerDeploy, contract, abi);

    const queryParams = new URLSearchParams(location.search);

    setLoading({
      title: 'Aguarde...',
      description: 'Estamos processando a transação na rede blockchain',
    });

    const pinataInfo = await pinata(
      JSON.stringify({
        collection: collection.id,
        image: nft.id,
        chainId: process.env.REACT_APP_CHAIN
      })
    );

    if (!pinataInfo) {
      return setAlertModal({
        type: 'error',
        title: 'Oops!',
        description: 'Erro ao gerar imagem',
      });
    }
    if (!pinataInfo.success) {
      return setAlertModal({
        type: 'error',
        title: 'Oops!',
        description: 'NFT já mintado',
      });
    }

    const gasPrice = await providerDeploy.getGasPrice();

    setLoading({
      title: 'Finalizando',
      description: 'Estamos concluindo o MINT do seu ativo',
    });

    const response = await MintCollection(
      contractForMint,
      walletDeploy,
      collection.price,
      pinataInfo.ipfs,
      gasPrice,
      promo,
      priceScreen
    );

    if (response.code === -1) {
      setLoading(null);
      setModal(false);
      return setAlertModal({
        type: 'error',
        title: 'Oops!',
        description: 'Sua transação não pode ser completada',
      });
    }

    if (response.code === -32603 || response.code === -32000) {
      setLoading(null);
      setModal(false);

      return setAlertModal({
        type: 'error',
        title: 'Saldo insuficiente',
        description: response?.data?.message ? response?.data?.message : 'Por favor, adicione saldo ou troque de carteira para continuar',
      });
    }

    if (response.code === 4001) {
      setLoading(null);
      setModal(false);

      return setAlertModal({
        type: 'error',
        title: 'Oops!',
        description: 'Operação cancelada pelo usuario',
      });
    }

    const txId = response.transactionHash;

    if (txId === undefined) {
      setLoading(null);
      setModal(false);

      return setAlertModal({
        type: 'error',
        title: 'Oops!',
        description: 'Operação cancelada pelo usuario',
      });
    }

    handleSuccessMint(txId, walletDeploy);
  };

  const callbackCrossMint = async () => {
    return window.location.href;
  };

  const handleSuccessMint = async (txId, walletDeploy) => {
    NFT.update(nft.id, {
      hash: txId,
      walletAddress: walletDeploy,
      promo: promo,
      bought_in: new Date(),
      type_purchase: "cripto"
    }).subscribe(
      (res) => {
        setLoading(null);
        setModal(false);
        success();

        if (promo == true) {
          var priceMetaExp = priceScreen;
        } else {
          var priceMetaExp = collection?.price;
        }

        const payload = {
          contractAddress: collection?.contract,
          walletAddress: wallet,
          chainId: process.env.REACT_APP_CHAIN,
          hashNft: nft.id,
          hashTransaction: txId,
          amount: priceMetaExp
        }
    
        const response = webhookMetaexp(payload);

        setAlertModal({
          type: 'success',
          title: 'Parabéns',
          description:
            'A operação foi realizada com sucesso e seu NFT já está disponível. Você pode verificar em seu perfil.',
          labelLeft: 'Adicionar na Metamask',
          labelRight: 'Meu perfil',
          leftHandle: () => {
            AddTokenMetamask(collection.contract, collection.symbol, nft.image);
            closeAlert();
          },
          rightLink: window.location.origin + '/profile'
        });
      },
      (error) => {
        setLoading(null);
        setModal(false);
        console.log('Error ao atualizar NFT: ', error);
      }
    );
  };

  const handleSuccessMercadoPago = async (hashNft) => {
    setLoading(null);
    setModal(false);
    success();

    if (promo == true) {
      var priceMetaExp = priceScreen;
    } else {
      var priceMetaExp = collection?.price;
    }

    const payload = {
      contractAddress: collection?.contract,
      walletAddress: wallet,
      chainId: process.env.REACT_APP_CHAIN,
      hashNft: nft.id,
      hashTransaction: hashNft,
      amount: priceMetaExp
    }

    const response = webhookMetaexp(payload);

    setAlertModal({
      type: 'success',
      title: 'Parabéns',
      description:
        'A operação foi realizada com sucesso e seu NFT já está disponível. Você pode verificar em seu perfil.',
      labelLeft: 'Adicionar na Metamask',
      labelRight: 'Meu perfil',
      leftHandle: () => {
        AddTokenMetamask(collection.contract, collection.symbol, nft.image);
        closeAlert();
      },
      rightLink: window.location.origin + '/profile'
    });
  }

  const closeAlert = () => {
    setAlertModal(null);
  };

  setTimeout(() => {
    $('.xmint-btn').removeClass('crossmintButton-0-2-1').removeClass('crossmintButton-d0-0-2-4').removeClass("crossmintButton-d8-0-2-12").removeClass("crossmintButton-d2-0-2-6");
    $(".crossmintParagraph-0-2-3").html('Cartão de Crédito');
    $(".crossmintImg-0-2-2").hide();
  }, 500);

  setInterval(() => {
    $('.xmint-btn').removeClass('crossmintButton-0-2-1').removeClass('crossmintButton-d0-0-2-4').removeClass("crossmintButton-d8-0-2-12").removeClass("crossmintButton-d2-0-2-6");
    $(".crossmintParagraph-0-2-3").html('Cartão de Crédito');
    $(".crossmintImg-0-2-2").hide();
  }, 1000);

  $( ".xmint-btn" ).on( "click", function() {
    setLoading(null);
    setAlertModal(null);
    handleCloseModals();
  } );

  initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);
  console.log(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);
  const initialization = {
    amount: priceMP,
    paymentMethods: {
      ticket: "all",
      bankTransfer: "all",
      creditCard: "all",
      debitCard: "all",
      mercadoPago: "all",
    },
    visual: {
      style: {
        theme: 'dark',
      }
    }
  };

  const customization = {
    paymentMethods: {
      ticket: "all",
      bankTransfer: "all",
      creditCard: "all",
      debitCard: "all",
      mercadoPago: "all",
    },
    visual: {
      style: {
        theme: 'dark',
      }
    }
  };

  const onSubmit = async (formData) => {
    // callback chamado ao clicar no botão de submissão dos dados
    console.log("formData", formData);
    formData.transaction_amount = parseFloat(formData.transaction_amount.toFixed(2));
    formData.network = process.env.REACT_APP_CHAIN;

    return new Promise((resolve, reject) => {
      fetch("https://us-central1-metaexp-mobiup.cloudfunctions.net/app/mercadopago/invoice", {
          method: "POST",
          headers: {
          "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
      }).then((response) => response.json())
        .then((response) => {
          // receber o resultado do pagamento
          console.log("mp view ", response);
          console.log("response?.body?.id", response?.body?.id);
          if (response?.body?.id != "") {
            if (response?.body?.status == "approved") {
              var payload = {
                mercado_pago: response,
                walletAddress: wallet,
                promo: promo,
                contractAddress: collection?.contract,
                chainId: process.env.REACT_APP_CHAIN,
                id: nft.id,
                metaData: nft.metadado
              }
              fetch("https://us-central1-metaexp-mobiup.cloudfunctions.net/app/mercadopago/create/nft", {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json",
                  },
                  body: JSON.stringify(payload),
              }).then((responseCreateNft) => responseCreateNft.json())
                .then((responseCreateNft) => {
                  // receber o resultado do pagamento
                  handleCloseModals();
                  handleSuccessMercadoPago(responseCreateNft.txhash);
                  return responseCreateNft;
                }).catch((errorCreateNft) => {
                  // lidar com a resposta de erro ao tentar criar o pagamento
                  console.log("mp create nft error", errorCreateNft);
                  handleCloseModals();
                  return setAlertModal({
                    type: 'error',
                    title: 'Oops!',
                    description: 'Erro ao realizar envio de NFT para sua wallet entre em contato com suporte!',
                  });
                });
              // mercadoPagoCreateNft(payload).then(function (responseCreateNft){
              //   console.log("mp create nft ", responseCreateNft);
              //   handleSuccessMercadoPago(responseCreateNft.txhash);
              // }).catch(function (errorCreateNft){
              //   console.log("mp create nft error", errorCreateNft);
              // });
            } else {
              //reject();
              handleCloseModals();
              return setAlertModal({
                type: 'error',
                title: 'Oops!',
                description: 'Pagamento rejeitado!',
              });
            }
          } else {
            //reject();
             handleCloseModals();
            return setAlertModal({
              type: 'error',
              title: 'Oops!',
              description: 'Erro ao realizar pagamento!',
            });
          }
          //resolve();
        }).catch((error) => {
          // lidar com a resposta de erro ao tentar criar o pagamento
          console.log(error);
          handleCloseModals();
          return setAlertModal({
            type: 'error',
            title: 'Oops!',
            description: 'Erro ao realizar pagamento. Confira os dados ou fale com sua operadora de cartão!',
          });
          //reject();
        });
      });
  };

  const onError = async (error) => {
    // callback chamado para todos os casos de erro do Brick
    console.log(error);
    handleCloseModals();
    return setAlertModal({
      type: 'error',
      title: 'Oops!',
      description: 'Erro ao realizar pagamento!',
    });
  };
  
  const onReady = async () => {
    /*
      Callback chamado quando o Brick estiver pronto.
      Aqui você pode ocultar loadings do seu site, por exemplo.
    */
  };
  
  return (
    <>

      {loading ? (
        <ModalDefault
          isOpen={loading ? true : false}
          type='loading'
          title={loading.title}
          description={loading.description}
        ></ModalDefault>
      ) : (
        <>
          {alertModal ? (
            <ModalDefault
              isOpen={alertModal ? true : false}
              type={alertModal.type}
              title={alertModal.title}
              description={alertModal.description}
              leftHandle={alertModal.leftHandle ?? closeAlert}
              rightHandle={closeAlert}
            ></ModalDefault>
          ) : (
            <ModalApp isOpen={modal} closeModal={toggle} label={''}>
              <div className='container d-flex align-items-center gap-3'>
                <div className='card-image'>
                  <img
                    src={nft.image}
                    className='rounded-3'
                    alt='Imagem do NFT'
                    width={130}
                  />
                </div>

                <div className='container'>
                  <div className='d-flex justify-content-between border-bottom align-items-center mb-2 pb-1'>
                    <h3>{nft.name}</h3>
                  </div>

                  <div className='d-flex justify-content-between bg-dark bg-opacity-25 rounded-3 p-2'>
                    <span className='fw-light'>Preço: </span>
                    <div className='d-grid'>
                      <span className='fw-bolder text-end fs-5'>
                        {promo == true ? priceScreen : price} {crypto}
                      </span>
                      <small className="text-primary text-end">{priceBRL}</small>
                    </div>
                  </div>
                </div>
              </div>

              <span>Formas de pagamento:</span>

              <div className='row'>
                <div className='col-12'>
                  <button
                    className='btn btn-primary w-100'
                    type='button'
                    onClick={mint}
                  >
                    {crypto}
                  </button>
                </div>
                <div className='col-12 mt-3'>
                  <div>
                    <button className="btn btn-primary w-100" onClick={() => handleOpenMercadoPagoModalNFT()}>
                      Cartão de Crédito <i className="fa-regular fa-credit-card"></i>
                    </button>
                  </div>
                </div>
                <div className='col-12 mt-3'>
                  {collection?.price >= 10 ? (
                    <div>
                      <button className="btn btn-primary w-100" onClick={() => handleOpenPixModalNFT()}>
                        PIX <i className="fa-brands fa-pix"></i>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button className="btn btn-primary w-100" disabled={true}>
                        PIX <i className="fa-brands fa-pix"></i>
                      </button>
                      <p className="text-center fs-7">
                        <small>Valor mínimo por transação: R$ 30,00</small>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </ModalApp>
          )}
        </>
      )}

      <ModalApp
          isOpen={openPixNFTModal}
          closeModal={handleCloseModals}
          label="Comprar NFT com PIX"
          centered={true}
          size="sm"
      >
          <CheckoutPix collection={collection} l={handleCloseModals} setShowCheckout={setShowCheckout} isOpen={showCheckout} closeModal={handleCloseCheckout} modalLabel={setActuallyLabel} nft={nft} promo={promo} priceScreen={priceScreen} />
      </ModalApp>

      <ModalApp
        isOpen={openMercadoPagoNFTModal}
        closeModal={handleCloseModals}
        label="Comprar NFT com Cartão"
        centered={true}
        size="sm"
      >
       <CardPayment
          initialization={initialization}
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
        />
      </ModalApp>

      <button
        type='button'
        className='btn btn-primary btn-lg w-100'
        onClick={toggle}
        disabled={disabled}
      >
        {children}
      </button>
    </>
  );
};
