import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../firebaseConfig';
import { animateScroll as scroll } from 'react-scroll';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { toast } from 'react-toastify';
import price from "../services/price";

export const truncateAddress = (address) => {
  if (!address) return 'No Account';
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const scrollToTop = () => {
  scroll.scrollToTop({
    duration: 150,
    delay: 0,
    smooth: 'easeInQuint',
  });
};

export const uploadImage = async (file, folder, fileName = null) => {
  let destination = `${folder}${file.name}`;
  if (fileName != null) {
    destination = `${folder}${fileName}`;
  }

  const storageRef = ref(storage, destination);
  const metadata = {
    contentType: file.type,
  };

  const uploadTask = await uploadBytes(storageRef, file, metadata);

  console.log('uploadtask', uploadTask);
  return await getDownloadURL(uploadTask.ref);
};

export const checkWhitelistDate = (data) => {
  const inititalDateWhiteListed = new Date(data.begin_waitlist_date * 1000);
  const endlDateWhiteListed = new Date(data.end_waitlist_date * 1000);
  const today = new Date();

  return today >= inititalDateWhiteListed && today <= endlDateWhiteListed
    ? true
    : false;
};

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const copyAddress = (text) => {
  toast.success("Link copiado para a área de transferência.", {});
  navigator.clipboard.writeText(text)
}

export const calcPriceBrlNft = (amount, crypto, priceNFT) => {

  let priceC = localStorage.getItem('price')
  const priceCoin = JSON.parse(priceC)

  const price = priceCoin[crypto]
  const amountPrice = parseFloat(priceNFT * price).toFixed(2);

  return "R$ " + amountPrice.toString().replace(".", ",");
}

export const validarCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length !== 11) {
    return false;
  }


  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }


  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }

  let remainder = sum % 11;
  let digit1 = (remainder < 2) ? 0 : 11 - remainder;

  if (parseInt(cpf.charAt(9)) !== digit1) {
    return false;
  }


  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }

  remainder = sum % 11;
  let digit2 = (remainder < 2) ? 0 : 11 - remainder;

  if (parseInt(cpf.charAt(10)) !== digit2) {
    return false;
  }

  return true;
}

export const TabTitle = (newTitle) => {
  return document.title = newTitle;
}

export const getIpAddress = async () => {
  const response = await fetch('https://api.ipify.org/?format=json');
  const data = await response.json();
  const ipAddress = data.ip;
  return ipAddress;
};

export const getGeolocation = () => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      return `${latitude},${longitude}`
    });
  }
};

export const walletFormat = (wallet) => {
  if (wallet.length <= 10) {
    return wallet;
  }

  const primeiraParte = wallet.substring(0, 5);
  const ultimaParte = wallet.substring(wallet.length - 6);

  return `${primeiraParte}...${ultimaParte}`;
}

export const formatarValorReal = (valor) => {
  try {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  } catch (e) {
    return valor;
  }

};

export const getPrice = async (quote) => {
  const response = await price({
    "quote": quote
  });
  return response.data
}

export const formatString = (str) => {
  const words = str.split('-');
  const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const formattedString = formattedWords.join(' ');
  return formattedString;
}