import api from "./api";

export const compiler = (data) => {
    return api.post('/compiler', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const updateCollection = (data) => {
    return api.post('/updateCollection', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}
export const pinata = (data) => {
    return api.post('/pinata', data)
        .then(response => response.data)
        .catch(error => console.log(error))
}

export const updatePinata = (data) => {
    return api.post('/updatePinata', data)
        .then(response => response.data)
        .catch(error => console.log(error))
}

export const contractUri = (data) => {
    return api.post('/contractUri', data)
        .then(response => response.data)
        .catch(error => console.log(error))
}