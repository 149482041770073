import styled from "styled-components";

export const ProfileContent = styled.div`
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background-color: transparent;
        border-radius: 0;
        margin: 0 10px; 
        border-bottom: 3px solid ${props => props.theme.primary};
    }
    
     .nav-pills .nav-link {
        color: #FFF;
     }

`;