import styled from 'styled-components';

export const Content = styled.section`
   margin: 5rem 0;
   // padding: 2rem 0;
   min-height: 700px;

   h1{
      text-transform: uppercase;
      font-weight: 700 !important;
      letter-spacing: -4px;
      line-height: 135%;
      padding-left: 30px;
      position: relative;
   }

   h1:before{
      content: '';
    height: calc(100% - 20px);
    width: 7px;
    background-color: #ff4f81;
    position: absolute;
    left: 0;
    top: 20px;
   }

   .text-featured{
      background-color: #ff4f81;
      color: #fff;
      padding: 0 10px;
   }

   strong{
      font-weight: 700;
   }

   @media(max-width: 567px){
      .container{
         padding: 0;
      }
   }

`;
