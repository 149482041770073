"use strict";
var __extends =
    (this && this.__extends) ||
    (function () {
        var extendStatics = function (d, b) {
            extendStatics =
                Object.setPrototypeOf ||
                ({ __proto__: [] } instanceof Array &&
                    function (d, b) {
                        d.__proto__ = b;
                    }) ||
                function (d, b) {
                    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
                };
            return extendStatics(d, b);
        };
        return function (d, b) {
            extendStatics(d, b);
            function __() {
                this.constructor = d;
            }
            d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
        };
    })();
exports.__esModule = true;
exports.User = void 0;
var nanocollection_1 = require("./nanocollection");
var User = /** @class */ (function (_super) {
    __extends(User, _super);
    function User() {
        return (_super !== null && _super.apply(this, arguments)) || this;
    }
    User.path = process.env.REACT_APP_ENVIRONMENT + "_users";
    return User;
})(nanocollection_1.NanoCollection);
exports.User = User;
