import * as S from '../styles'
import { useState } from 'react'
import {useCollection} from "../../../web3/providers/CollectionContext";
import {Link} from "react-router-dom";
import { networkParams } from "web3/networks/networks";
import { formatarValorReal } from '../../../utils/utils';
import ImgAvatar from 'assets/img/logo-black.png';

export const  FirstStepPix = ({changeStep, rfqId, collection}) => {

  const [quantity, setQuantity] = useState(1)
  const {nft} = useCollection();

  function addQuantity(){
    setQuantity(quantity + 1)
  }

  function removeQuantity(){
    if(quantity === 1) return;

    setQuantity(quantity - 1)
  }

return(
    <S.CheckoutContainer>
       <S.ContentDetails>
        <div>
          <p>{collection?.name ? collection?.name : 'NFT'}</p>
          <span>{collection?.symbol ? collection?.symbol : 'NFT'}</span>
          <Link to={networkParams[collection?.network]?.address+collection?.contract} target="_blank" className='text-white'>Ver na blockchain</Link>
        </div>
        <S.ImageContainer>
          <img src={collection?.image ? collection?.image : ImgAvatar} alt=""/>
        </S.ImageContainer>
      </S.ContentDetails>

      <strong>Resumo</strong>

      <S.CheckoutDetails>
        <div>
          <p>Quantidade</p>
          <S.QuantityContainer>
            <button disabled onClick={removeQuantity}>-</button>
              <span>{ quantity }</span>
            <button disabled onClick={addQuantity}>+</button>
          </S.QuantityContainer>
        </div>
        <div>
          <p>{collection?.name ? collection?.name : 'NFT'}</p>
          <span>{formatarValorReal(rfqId?.quote?.result?.cash?.settlementValue)}</span>
        </div>
        <div>
          <p>Taxa da rede</p>
          <span>{formatarValorReal(rfqId?.quote?.result?.cash?.gas)} </span>
        </div>
        <div>
          <p>Taxa de conveniência</p>
          <span>{formatarValorReal(rfqId?.quote?.result?.processingFee)}</span>
        </div>
        <div>
          <p> <strong>Total</strong> </p>
          <span>{formatarValorReal(rfqId?.quote?.result?.cash?.value)}</span>
        </div>
      </S.CheckoutDetails>

      <S.ContinueButton onClick={changeStep}>
        Continuar
      </S.ContinueButton>
      

    <S.CheckoutFooter>
      <a href='https://itspay.io/'> <strong>Powered by</strong>  <img src="https://itsramp.io/itspay_assets/img/ItsPay.png?t=1683717564" alt="" /></a>
    </S.CheckoutFooter>
    
    </S.CheckoutContainer>
  )

}