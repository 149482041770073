import { useEffect, useState } from 'react'
import { useApp } from "web3/providers/AppContext";
import {FirstStepPix} from "./Steps/firstStep"
import {SecondStepPix} from "./Steps/secondStep"
import {ThirdStepPix} from "./Steps/thirdStep"
import {FourthStepPix} from "./Steps/fourthStep"
import { FifthStep } from './Steps/fifthStep'
import {SixthStepPix} from "./Steps/sixthStep"
import {SuccessStep} from "./Steps/successStep"
import {ErrorStep} from "./Steps/errorStep"
import {createRfqCrypto, updateRfq} from "../../services/pix.service";
import {LoadingModal} from 'components/Loading';


export const CheckoutCrypto = ({isOpen, modalLabel, closeModal, crypto}) => {
const { wallet, creator } = useApp();
const [rfqId, setRfqid] = useState(null);
const [isLoading, setIsLoading] = useState(false);
const [statusRFQ, setStatusRFQ] = useState({success:true});

const [screen,setScreen] = useState('firstStep')
const [emailValue, setEmailValue] = useState(creator?.email);

const [invoice, setInvoice] = useState();

useEffect(() => {
  if(!isOpen){
    setScreen('firstStep')
  }
},[isOpen])

useEffect(() => {
  async function fetchData() {
    setIsLoading(true);
    const payload = {
      network: 5,
      quantity: 10.0,
      wallet: wallet
    }
  
    const response = await createRfqCrypto(payload);
    console.log(response);
    if(!response.success) {
      setStatusRFQ(response);
      return;
    }
    setRfqid(response.rfq);
    localStorage.setItem(crypto?.imageId, JSON.stringify(response.rfq))
    setIsLoading(false);
  }

  if(!JSON.parse(localStorage.getItem(crypto?.imageId))){
    fetchData();
  }else{

    async function updateData() {  
      setIsLoading(true); 
      const rfq = JSON.parse(localStorage.getItem(crypto?.imageId));
      const payload = {
        network: 5,
        quantity: 10,
        wallet: wallet,    
        rfqId: rfq.id
      }  
      const response = await updateRfq(payload);
      setIsLoading(false);
      if(!response.success) {  
        setStatusRFQ(response);
        fetchData();
        return;
      }
      setRfqid(rfq)
    }
    updateData();
    
  }

},[])



function changeToSecondStep(){
  setScreen('secondStep')
  modalLabel('Confirmar carteira e e-mail')
}

function changeToThirdStep(){
  setScreen('thirdStep')
  modalLabel('Confirmar e-mail')
}

function changeToFourthStep(){
  setScreen('fourthStep')
  modalLabel(' Identificação pessoal')
}

function changeToFifthStep(){
  setScreen('fifthStep')
  modalLabel('Efetuar pagamento')
}

function changeToSixthStep(){
  setScreen('sixthStep')
  modalLabel('')
}

function changeToSuccessStep(){
  setScreen('successStep')
  modalLabel('')
}

if(isLoading){
  return(
    <LoadingModal/>
  )
}

if(!statusRFQ.success){  
 return(<ErrorStep errorMsg={statusRFQ?.message} buttonLabel={'Refazer compra'} changeStep={closeModal} crypto={crypto}/>)
}

if(rfqId) {
  return(
    <>
     {screen === "firstStep" && <FirstStepPix changeStep={changeToSecondStep} rfqId={rfqId}/> } 
     {screen === "secondStep" && <SecondStepPix emailValue={emailValue} setEmailValue={setEmailValue} changeStep={changeToThirdStep} closeModal={closeModal}/>}
     {screen === "thirdStep" && <ThirdStepPix emailValue={emailValue} changeStep={changeToFourthStep}/>}
     {screen === "fourthStep" && <FourthStepPix closeModal={closeModal} changeStep={changeToFifthStep} emailValue={emailValue} rfqId={rfqId} invoice={invoice} setInvoice={setInvoice} buttonLabel={<div> Pagar <i className="fa-brands fa-pix me-2"/> </div>} />}
     {screen === "fifthStep" && <FifthStep buttonLabel={"Nova Compra"} changeStep={changeToSixthStep} rfqId={rfqId} invoice={invoice}/>}
     {screen === "sixthStep" && <SixthStepPix rfqId={rfqId} crypto={crypto} changeStep={changeToSuccessStep} />}
     {screen === "successStep" && <SuccessStep rfqId={rfqId} crypto={crypto} changeStep={closeModal} buttonLabel={"Meus CRPLAYs"} />}
    </>
   )
}


}