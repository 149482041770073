import {useEffect, useState} from 'react'
import {useApp} from "web3/providers/AppContext";
import {FirstStepPix} from "./Steps/firstStep"
import {SecondStepPix} from "./Steps/secondStep"
import {ThirdStepPix} from "./Steps/thirdStep"
import {FourthStepPix} from "./Steps/fourthStep"
import {FifthStep} from './Steps/fifthStep'
import {SixthStepPix} from "./Steps/sixthStep"
import {SuccessStep} from "./Steps/successStep"
import {ErrorStep} from "./Steps/errorStep"
import {createRfq,updateRfq} from "../../services/pix.service";
import {pinata} from 'services/contract.service';
import {LoadingModal} from 'components/Loading';

export const CheckoutPix = ({isOpen, modalLabel, closeModal, nft, setShowCheckout, collection, promo, l, priceScreen}) => {
  const { signer, wallet, connectWallet, connection, balance } = useApp();
  
  const [rfqId, setRfqid] = useState(null);
  const [cid, setCid] = useState('0');
  const [isLoading, setIsLoading] = useState(false);
  const [statusRFQ, setStatusRFQ] = useState({success: true});
  const [screen, setScreen] = useState('');
  const [emailValue, setEmailValue] = useState();

  const [invoice, setInvoice] = useState();

  async function fetchData() {   
    let provider = signer;

    if (wallet == "" || wallet == undefined) {
      setIsLoading(false);
      const connected = await connectWallet();
      return;
    } else {
      setScreen('firstStep');
      setIsLoading(true);
      const pinataInfo = await pinata(
        JSON.stringify({
          collection: collection.id,
          image: nft.id,
          chainId: process.env.REACT_APP_CHAIN
        })
      );
    
      console.log('pinata: ', pinataInfo);
    
      if (!pinataInfo) {
        return(<ErrorStep errorMsg={'Erro ao gerar imagem'} buttonLabel={'Refazer compra'} changeStep={changeToFirstStep} nft={nft}/>)
      }
  
      if (!pinataInfo.success) {
        return(<ErrorStep errorMsg={'NFT já mintado'} buttonLabel={'Refazer compra'} changeStep={changeToFirstStep} nft={nft}/>)
      }
  
      setCid(pinataInfo.ipfs);
  
      console.log(pinataInfo.ipfs);
  
      var functionName = '';
      var priceNFT = collection.price;
      if (promo == true) {
        functionName = 'mintPromo';
        priceNFT = priceScreen;
      }
  
      const payload = {
        network: nft.network,
        contract: nft.contractAddress,
        amount: 1,
        priceNFT: priceNFT,
        wallet: wallet,
        cid: pinataInfo.ipfs,
        functionName: functionName
      }
  
      const response = await createRfq(payload);
      
      if(!response.success) {
        setIsLoading(false);
        setStatusRFQ(response);
        return;
      }
      setRfqid(response.rfq);
      localStorage.setItem(nft?.collectionId,JSON.stringify(response.rfq))
      setIsLoading(false);
    }
  }

  async function updateData() {  
    setIsLoading(true); 
    const rfq = JSON.parse(localStorage.getItem(nft?.collectionId));

    const pinataInfo = await pinata(
      JSON.stringify({
        collection: collection.id,
        image: nft.id,
        chainId: process.env.REACT_APP_CHAIN
      })
    );
  
    console.log('pinata: ', pinataInfo);
  
    if (!pinataInfo) {
      return(<ErrorStep errorMsg={'Erro ao gerar imagem'} buttonLabel={'Refazer compra'} changeStep={changeToFirstStep} nft={nft}/>)
    }

    if (!pinataInfo.success) {
      return(<ErrorStep errorMsg={'NFT já mintado'} buttonLabel={'Refazer compra'} changeStep={changeToFirstStep} nft={nft}/>)
    }

    setCid(pinataInfo.ipfs);

    var functionName = '';
    var priceNFT = collection.price;
    if (promo == true) {
      functionName = 'mintPromo';
      priceNFT = priceScreen;
    }

    const payload = {
      network: nft.network,
      contract: nft.contractAddress,
      amount: 1,
      priceNFT: priceNFT,
      wallet: wallet,
      rfqId: rfq.id,
      cid: pinataInfo.ipfs,
      functionName: functionName
    }  
    const response = await updateRfq(payload);
    setIsLoading(false);
    if(!response.success) {  
      setStatusRFQ(response);
      fetchData();
      return;
    }
    setRfqid(rfq)
  }

  useEffect(() => {
    if (wallet == "" || wallet == undefined) {
      setIsLoading(false);
      closeModal();
      setShowCheckout(false);
      setRfqid(null);
      l();
      console.log("true");
      const connected = connectWallet();
      return;
    } else {
      if(!isOpen){
        setScreen('firstStep')
      }
    }
  },[isOpen])

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem(nft?.collectionId))) {
      if (wallet == "" || wallet == undefined) {
        setIsLoading(false);
        const connected = connectWallet();
        return;
      } else {
        fetchData();
      }
    } else {
      console.log("updateData");
      updateData();
    }
  },[]);

  function changeToSecondStep(){
    setScreen('secondStep')
    modalLabel('Confirmar carteira e e-mail')
  }

  function changeToThirdStep(){
    setScreen('thirdStep')
    modalLabel('Confirmar e-mail')
  }

  function changeToFourthStep(){
    setScreen('fourthStep')
    modalLabel(' Identificação pessoal')
  }

  function changeToFifthStep(){
    setScreen('fifthStep')
    modalLabel('Efetuar pagamento')
  }

  function changeToSixthStep(){
    setScreen('sixthStep')
    modalLabel('')
  }

  function changeToSuccessStep(){
    setScreen('successStep')
    modalLabel('')
  }

  function changeToFirstStep(){
    modalLabel('');
    setScreen('firstStep');
    fetchData();
  }

  if(isLoading){
    return(
      <LoadingModal/>
    )
  }

  if(!statusRFQ.success) {  
    return(<ErrorStep errorMsg={statusRFQ?.message} buttonLabel={'Refazer compra'} changeStep={changeToFirstStep} nft={nft}/>)
  }

  if(rfqId) {
    return(
      <>
      {screen === "firstStep" && <FirstStepPix changeStep={changeToSecondStep} rfqId={rfqId} collection={collection}/> } 
      {screen === "secondStep" && <SecondStepPix emailValue={emailValue} setEmailValue={setEmailValue} changeStep={changeToThirdStep} closeModal={closeModal}/>}
      {screen === "thirdStep" && <ThirdStepPix emailValue={emailValue} changeStep={changeToFourthStep}/>}
      {screen === "fourthStep" && <FourthStepPix nft={nft} closeModal={closeModal} changeStep={changeToFifthStep} emailValue={emailValue} rfqId={rfqId} invoice={invoice} setInvoice={setInvoice} buttonLabel={<div> Pagar <i className="fa-brands fa-pix me-2"/> </div>} />}
      {screen === "fifthStep" && <FifthStep changeToFirstStep={changeToFirstStep} nft={nft} buttonLabel={"Nova Compra"} changeStep={changeToSixthStep} rfqId={rfqId} invoice={invoice}/>}
      {screen === "sixthStep" && <SixthStepPix collection={collection} rfqId={rfqId} nft={nft} changeStep={changeToSuccessStep} promo={promo} />}
      {screen === "successStep" && <SuccessStep rfqId={rfqId} nft={collection} changeStep={closeModal} buttonLabel={"Meus NFTs"} />}
      </>
    )
  }
}