// import React from 'react';
// import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

// import { Home } from './pages/Home';
// import { CollectionPage } from './pages/Collection';
// import { CollectionDetail } from './pages/CollectionDetail';
// import { Explorer } from './pages/Explorer';
// import { Profile } from './pages/Profile';
// import PageTransition from 'components/PageTransition/PageTransition';
// import { Sales } from 'pages/Sales';
// import { LoginAdmin } from 'pages/LoginAdmin';
// import { useAuthAdmin } from 'contexts/adminContext';

// function AppRouter() {
//   const location = useLocation();
//   const { isAdmin } = useAuthAdmin();

//   return (
//     <PageTransition location={location}>
//       <Routes>
//         <Route path='/' element={<Home />} />
//         <Route path='/collection/:address' element={<CollectionPage />} />
//         <Route path='/collection/:address/:id' element={<CollectionDetail />} />
//         <Route path='/explorer' element={<Explorer />} />
//         <Route path='/profile' element={<Profile />} />
//         <Route
//           path='/sales'
//           element={isAdmin ? <Sales /> : <Navigate to="/login-admin" />}
//         />
//         <Route path='login-admin' element={<LoginAdmin />} />
//         <Route path='*' element={<Home />} />
//       </Routes>
//     </PageTransition>
//   );
// }

// export default AppRouter;

import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { Home } from './pages/Home';
import { CollectionPage } from './pages/Collection';
import { CollectionDetail } from './pages/CollectionDetail';
import { Explorer } from './pages/Explorer';
import { Profile } from './pages/Profile';
import PageTransition from 'components/PageTransition/PageTransition';
import { Sales } from 'pages/Sales';
import { LoginAdmin } from 'pages/LoginAdmin';
import { useAuthAdmin } from 'contexts/adminContext';

function AppRouter() {
  const location = useLocation();
  const { isAdmin } = useAuthAdmin();

  return (
    <PageTransition location={location}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/collection/:address' element={<CollectionPage />} />
        <Route path='/collection/:address/:id' element={<CollectionDetail />} />
        <Route path='/explorer' element={<Explorer />} />
        <Route path='/profile' element={<Profile />} />
        <Route
          path='/sales'
          element={isAdmin ? <Sales /> : <LoginAdmin />}
        />
        <Route path='login-admin' element={<LoginAdmin />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </PageTransition>
  );
}

export default AppRouter;


