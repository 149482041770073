import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './assets/css/custom.css';
import './assets/plugins/fontawesome/css/all.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles/globalCss.css';

import { AppProvider } from 'web3/providers/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { CollectionProvider } from 'web3/providers/CollectionContext';
import { Helmet } from 'react-helmet';
import AdminContextProvider from 'contexts/adminContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProvider>
    <CollectionProvider>
      <BrowserRouter>
        <AdminContextProvider>
          <Helmet>
            <meta charSet='utf-8' />
            {/* <title>MetaEXP</title> */}
            <link rel='icon' type='image/ico' href='favicon.ico' />
          </Helmet>
          <App />
        </AdminContextProvider>
      </BrowserRouter>
    </CollectionProvider>
  </AppProvider>
);
