import * as S from '../styles'
import { useEffect, useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isInteger } from 'lodash';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { checkPixStatus, webhookMetaexp } from "../../../services/pix.service";
import { NFT } from 'models/nft';
import { useApp } from 'web3/providers/AppContext';

export const  SixthStepPix = ({rfqId, nft, collection, changeStep, promo}) => {

//Mockado depois pegar esses status da api

const [countDown, setCountDown] = useState(300);
const [reqStatus, setReqStatus] = useState('');
const [activeStep, setActiveStep] = useState(1);
const timerId = useRef();
const { wallet } = useApp();

const pixRecivded = reqStatus === "RFQ_STATUS_FULFILLED" ? true : false
const processValidation = reqStatus === "RFQ_STATUS_SETTLEMENT" || "RFQ_STATUS_FULFILLED"  ? true : false
const processed = reqStatus === "RFQ_STATUS_SETTLEMENT" || "RFQ_STATUS_FULFILLED"  ? true : false
const validated = reqStatus === "RFQ_STATUS_SETTLEMENT" || "RFQ_STATUS_FULFILLED"  ? true : false

useEffect(() => {
    timerId.current = setInterval( () => {
    setCountDown( (prev) =>  { 

      if(isInteger(prev/5)) {
        const checkStatus =  async () => {
            const response = await checkPixStatus({
              rfqId: rfqId.id,
              network: nft.network
            })
            
            var externalTransactionId = response.rfq.order.externalTransactionId;

            if(response.rfq.status === "RFQ_STATUS_SETTLEMENT") {
              setReqStatus("RFQ_STATUS_SETTLEMENT");
            }

            if(response.rfq.status === "RFQ_STATUS_FULFILLED") {
              
              
              NFT.update(nft.id, {
                hash: externalTransactionId,
                walletAddress: wallet,
                promo: promo,
                bought_in: new Date(),
                type_purchase: "pix"
              }).subscribe(
                (res) => {
                  console.log(res);
                },
                (error) => {
                  console.log(error);
                }
              );

              if (promo == true) {
                var priceMetaExp = collection?.pricePromo;
              } else {
                var priceMetaExp = collection?.price;
              }
      
              const payload = {
                contractAddress: collection?.contract,
                walletAddress: wallet,
                chainId: process.env.REACT_APP_CHAIN,
                hashNft: nft.id,
                hashTransaction: externalTransactionId,
                amount: priceMetaExp
              }
          
              const response = webhookMetaexp(payload);

              setReqStatus("RFQ_STATUS_FULFILLED");

              setTimeout(() => {
                changeStep();
              }
              , 3000);
            }
        };
        checkStatus();
      } 
      
      return prev - 1

    });

    const activeStepInterval = setInterval(() => {
              setActiveStep(
                (prev) => prev + 1
              );
            }
            , 6000);

            if(activeStep > 3 ){
              clearInterval(activeStepInterval);
          }
    
  }, 1000);
  
  return () => clearInterval(timerId.current);

}, []);

useEffect(() => {
  if(countDown <= 0){
    clearInterval(timerId.current);
  }    
}, [countDown]);

return(
    <S.CheckoutContainer>
      <h4>
        Pedido {rfqId.id}
      </h4>

      <S.PixContent>
        <h3>{nft?.symbol}</h3>
        <S.CheckStatus>
          <FontAwesomeIcon icon={faCheck} size='lg' className={validated && activeStep >= 1 ? "default active" : "default"} />
          <S.Separator/>
          <FontAwesomeIcon icon={faCheck} size='lg' className={ processValidation && activeStep >= 2 ? "default active" : "default"} />
          <S.Separator/>
          <FontAwesomeIcon icon={faCheck} size='lg' className={ processed  && activeStep >= 3 ? "default active" : "default"} />
          <S.Separator/>
          <FontAwesomeIcon icon={faCheck} size='lg' className={pixRecivded ? "default active" : "default"} />
        </S.CheckStatus>
      </S.PixContent>

      <S.StatusContent >
        <span className={validated && activeStep >= 1 && "active"}>Validando na Rede</span>
        <span className={processed &&  activeStep >= 2 &&"active"}>Pedido Validado</span>
        <span className={processValidation && activeStep >= 3 && "active"}>Processando seu pedido</span>
        <span className={pixRecivded &&  "active"}>Pix recebido com sucesso</span>
      </S.StatusContent>


    <S.CheckoutFooter>
      <a href='https://itspay.io/'> <strong>Powered by</strong>  <img src="https://itsramp.io/itspay_assets/img/ItsPay.png?t=1683717564" alt="" /></a>
    </S.CheckoutFooter>

    </S.CheckoutContainer>
  )

}