import React, { useEffect, useState } from 'react';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from '../CarouselCollection';
import { ButtonTabs, NavTab } from '../Tabs';
import { ButtonShowMore, Content } from './styles';
import { useCollection } from 'web3/providers/CollectionContext';
import { formatString } from 'utils/utils';

const Collections = () => {
  const { collections, categories } = useCollection();
  const [activeTab, setActiveTab] = useState('nav-all');
  const [visibleCategories, setVisibleCategories] = useState(3);

  useEffect(() => {
    setActiveTab('nav-all');
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleShowMoreClick = () => {
    setVisibleCategories(prevVisibleCategories => prevVisibleCategories + 5);
  };

  return (
    <Content>
      <div className='container contents py-5'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-xl-3 order-0'>
            <h1 className='fs-1 fw-bolder h1-title'>Coleções</h1>
          </div>
          <div className='col-12 col-md-8 col-xl order-2 order-xl-1'>
            <NavTab>
              <ButtonTabs
                tab='nav-all'
                icon={faClone}
                label='Todos'
                active={activeTab === 'nav-all'}
                onClick={() => handleTabClick('nav-all')}
                href='#first-category'
              />
              {categories.filter((category) =>
                collections.filter(
                  (localCollection) =>
                    localCollection?.category?.key === category.key
                ).length > 0
              ).map((category) => (
                <ButtonTabs
                  key={category.id}
                  tab={category.key}
                  icon={category.icon}
                  label={category.label}
                  active={activeTab === category.key}
                  onClick={() => handleTabClick(category.key)}
                />
              ))}
            </NavTab>
          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-12'>
            <div className='tab-content' id='nav-tabContent'>
              <div
                className={`tab-pane fade ${activeTab === 'nav-all' ? 'show active' : ''}`}
                id='nav-all'
                role='tabpanel'
              >
                <div className='mb-5 mt-4'>
                  <h3>Últimas coleções</h3>
                  <Carousel collections={collections} />
                </div>
                {categories.slice(0, visibleCategories).map((c, index) => (
                  <div
                    className='mb-5 mt-4'
                    key={'cat-' + c.key}
                  >
                    {collections.filter(
                      (localCollection) =>
                        localCollection?.category?.key === c.key
                    ).length > 0 && (
                        <div>
                          <h3>{formatString(c.label)}</h3>
                          <Carousel
                            collections={collections.filter(
                              (localCollection) =>
                                localCollection?.category?.key === c.key
                            )}
                          />
                        </div>
                      )}
                  </div>
                ))}
                {visibleCategories < categories.length && (
                  <ButtonShowMore onClick={handleShowMoreClick}>Ver mais</ButtonShowMore>
                )}
              </div>
              {categories.map((c, index) => (
                <div
                  className={`tab-pane fade ${activeTab === c.key ? 'show active' : ''}`}
                  id={c.key}
                  key={'cat-' + c.key}
                  role='tabpanel'
                >
                  <div className='mb-5 mt-4'>
                    <h3>{c.label}</h3>
                    <Carousel
                      collections={collections.filter(
                        (localCollection) =>
                          localCollection?.category?.key === c.key
                      )}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Collections;
