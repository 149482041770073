import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export const ModalApp = ({ isOpen, closeModal, label, children } ) => {
    return (
        <Modal isOpen={isOpen} toggle={closeModal} centered={true} backdrop={true}>
            <ModalHeader toggle={closeModal} className="border-0 bg-grey-800">
                <span className="fw-bold fs-4 text-white">{ label }</span>
            </ModalHeader>
            <ModalBody className="py-4 pt-0 d-grid gap-3 bg-grey-800 rounded-bottom">
                { children }
            </ModalBody>
        </Modal>
    );
}
