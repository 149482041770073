/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import ImgAvatar from 'assets/img/logo-black.png';
import { Content } from './styles';
import { useParams, Link, useLocation } from 'react-router-dom';
import { ButtonMint } from 'components/ButtonMint';
import { NFT } from 'models/nft';
import { useApp } from 'web3/providers/AppContext';
import { useCollection } from 'web3/providers/CollectionContext';
import { scrollToTop } from 'utils/utils';
import { getPrice, formatarValorReal } from "../../utils/utils";
//import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { mercadoPago, mercadoPagoCreateNft } from '../../services/pix.service';

export const CollectionDetail = () => {
  const { address, id }                               = useParams();
  const { wallet, user }                              = useApp();

  const [collection, setCollection]                   = useState({});
  const [nft, setNft]                                 = useState([]);
  const [quote, setQuote]                             = useState();
  const { getNft, getCollection }                     = useCollection();
  const [codePromoValue, setCodePromoValue]           = useState();
  const [successPromo, setSuccessPromo]               = useState(false);
  const [messagePromo, setMessagePromo]               = useState('');
  const [priceScreen, setPriceScreen]                 = useState(0);
  const [count, setCount]                             = useState(0);
  const [preferenceId, setPreferenceId]               = useState('');
  const [mercadoPagoCallBack, setMercadoPagoCallBack] = useState(false);
  const [hashNftId, setHashNftId]                     = useState('');
  const [priceMatic, setPriceMatic]                   = useState();
  const location                                      = useLocation();
  const queryParams                                   = new URLSearchParams(location.search);
  const payValue                                      = queryParams.get('pay');

  const amount = 1;

  const handleShareButtonClick = (title, text) => {
    if (navigator.share) {
      navigator.share({
        title: title,
        text: text,
        url: window.location.href
      })
        .then(() => console.log('Link compartilhado com sucesso!'))
        .catch((error) => console.error('Erro ao compartilhar o link:', error));
    } else {
      console.log('A API de compartilhamento não é suportada neste navegador.');
    }
  };

  const getUpdatedPrice = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (count == 0) {
          const responseMatic = getPrice('Matic');
          setPriceMatic(responseMatic)
        }
      }, 2000)
      Promise.all([priceMatic]).then(function (result) {
        console.log("Matic price:", result);
        resolve(result[0]);
      });
    });
  };

  const fetchQuote = async () => {
    console.log("fetchQuote 2");
    const updatedQuote = await getUpdatedPrice();

    if (successPromo == true) {
      setQuote(updatedQuote.toFixed(2) * collection?.pricePromo.toFixed(2));
    } else {
      try {
        setQuote(updatedQuote.toFixed(2) * collection?.price.toFixed(2));
      } catch (e) {
        console.log("setQuote -> ", e);
      }
    }
    setCount(1);
  };

  const handleSuccess = () => {
    NFT.object(id).subscribe((res) => {
      if (res) {
        setNft(res);
      }
    });
    setNft(nft);
  };

  const validateCode = async () => {
    if (codePromoValue == collection?.codePromo) {
      setSuccessPromo(true);
      setMessagePromo('Código Válido aproveite seu NFT!');
      setPriceScreen(collection?.pricePromo);
      const updatedQuote = await getUpdatedPrice();
      setQuote(updatedQuote * collection?.pricePromo);
      try {
        setQuote(updatedQuote.toFixed(2) * collection?.pricePromo.toFixed(2));
      } catch (e) {
        console.log("setQuote -> ", e);
      }
    } else {
      setSuccessPromo(true);
      setMessagePromo('Código Inválido!');
      setTimeout(() => {
        setSuccessPromo(false);
        setMessagePromo('');
      }, 3000);
    }
  }

  useEffect(() => {
    setNft(getNft(id));
    setCollection(getCollection(address));
    scrollToTop();
    fetchQuote();
  }, [priceMatic]);

  return (
    <Content>
      <div className='container my-5'>
        <div className='d-flex justify-content-end mb-4'>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='/explorer'>Explorar</Link>
              </li>
              <li className='breadcrumb-item'>
                <Link to={`/collection/${address}`}>{collection?.name}</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                Detalhes
              </li>
            </ol>
          </nav>
        </div>

        <div className='row g-5'>
          <div className='col-12 col-md-6'>
            <img
              src={nft.image}
              className='img-fluid image-nft rounded-3'
              alt=''
            />
          </div>

          <div className='col-12 col-md-6'>
            <div className='d-flex justify-content-between gap-3 align-items-center border-bottom pb-4'>
              <div className='d-flex gap-3 align-items-center'>
                <img
                  src={ImgAvatar}
                  width={80}
                  className='img-fluid rounded-3'
                />
                <div className='d-grid'>
                  <small className=''>Criado por</small>
                  <span className='fs-5 fw-bolder'>METAEXP</span>
                  {/* <span className="fs-6 text-primary">@username</span> */}
                </div>
              </div>
              <div>
                <span className='btn btn-primary' onClick={() => handleShareButtonClick(collection?.name, collection?.description)}>
                  <i className='bi bi-share-fill fs-6' />
                </span>
              </div>
            </div>

            <div className='py-3 border-bottom'>
              <h1 className='fw-bolder'>
                {nft?.metadado?.name}
              </h1>
              <p className=''>
                {collection?.description
                  ? collection?.description
                  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at vestibulum diam. Integer sit amet massa et erat vulputate lacinia id a elit.'}
              </p>
            </div>

            <div className='row border-bottom pb-4 mt-4'>
              <div className='col-6 py-3'>
                <p className=''>Preço</p>
                <div className='d-grid'>
                  <span className='fs-4 fw-bolder'>
                    {priceScreen ? priceScreen : collection?.price} MATIC
                  </span>
                  {!quote ? (
                    <span className="fs-6 fw-bolder ">Carregando...</span>
                  ) : (
                    <span className="fs-6 fw-bolder ">{formatarValorReal(quote)}</span>
                  )}
                </div>
              </div>
              <div className='col-3'></div>
            </div>

            <div className='d-flex flex-column gap-3 mt-5'>

              {collection?.statusPromo === true ? (
                <>
                  {nft.walletAddress ? (
                    nft.walletAddress === wallet ? (
                      null
                    ) : (
                      null
                    )
                  ) : (
                    successPromo ? (
                      <div className='d-flex' id='grid-promo'>
                        <p>{messagePromo}</p>
                      </div>
                    ) : (
                      <div className='d-flex' id='grid-promo'>
                        <input
                          className="form-control"
                          type="text"
                          id="codePromo"
                          onChange={(e) => setCodePromoValue(e.target.value)}
                          placeholder='CODEPROMO'
                          value={codePromoValue}
                        />

                        <button
                          className='btn btn-primary w-100'
                          type='button'
                          onClick={validateCode}
                        >
                          Validar Código
                        </button>
                      </div>
                    )
                  )}
                </>
              ) : (null)}

              <ButtonMint
                amount={amount}
                nft={nft}
                collection={collection}
                crypto='MATIC'
                disabled={nft.walletAddress}
                success={handleSuccess}
                priceBRL={formatarValorReal(quote)}
                priceMP={parseFloat(quote)}
                promo={successPromo}
                priceScreen={priceScreen}
              >
                {nft.walletAddress
                  ? nft.walletAddress === wallet
                    ? 'Você é o proprietário'
                    : 'Já adquirido'
                  : 'Compre agora'}
              </ButtonMint>
            
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};
