import styled from 'styled-components';

export const Content = styled.div`
  .offcanvas {
    background-color: ${({ theme }) => theme.bgsecondary};
    color: ${({ theme }) => theme.color};
  }

  .card-profile {
    background-color: ${({ theme }) => theme.bg100}!important;
  }

  .list-group-item {
    background-color: ${({ theme }) => theme.bg400};
    color: ${({ theme }) => theme.color};
  }
  
  .list-group-item:not(:last-child){
    border-bottom: 1px solid rgba(0,0,0,.05);
  }

  .card {
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.border};
  }

  div.image {
    border: 1px solid rgb(111, 111, 111);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-repeat: 'no-repeat';
    box-shadow: 2px 2px 2px 1px rgb(113 113 113 / 20%);
    margin-right: 10px;
  }
`;

export const ListMenuSideBar = styled.ul`
  list-style: none;
  padding-left: 5px;

  li {
    margin-bottom: 1rem;
  }

  li a {
    text-decoration: none;
    color: #fff;

    display: flex;
    gap: 10px;
    align-items: center;

    &.active i {
      color: ${(props) => props.theme.primary};
    }
  }

  li a i {
    transition: color 0.6s;
    font-size: 1.2rem;
  }
`;
