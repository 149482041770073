import styled from 'styled-components';

export const Content = styled.section `
    background-color: ${({theme}) => theme.bg100};
    color: ${({theme}) => theme.color};
    padding: 5em 0;
    
    .content-create i {
        font-size: 4rem; 
    }
    
    .content-create .icon {
        padding: 25px;
        border-radius: 20px;
        margin-bottom: 1em;
        display: flex;
        color: #FFFFFF;
        justify-content: center;
        width: 50%;
    }

    h4 {
        font-weight: bold;
    }
`;
