import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ContentNav } from './styles'

export const ButtonTabs = (props) => {
    return (
        <button className={`nav-link ${props.active && 'active'}`} id={`nav-${props.tab}`} data-bs-toggle="tab"
                data-bs-target={`#${props.tab}`} type="button" role="tab" aria-controls={ props.tab }
                aria-selected="true">
            <FontAwesomeIcon icon={ props.icon } className="me-1" /> { props.label }
        </button>
    )
}


export const NavTab = ({children}) => {
    return (
        <ContentNav>
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    { children }
                </div>
            </nav>
        </ContentNav>
    )
}