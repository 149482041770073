import React, { createContext, useContext, useEffect, useState } from 'react';

import { Loading } from 'components/Loading';
import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import Web3 from 'web3';
import { User } from 'models/user';

import { readContract, getBalanceOf, switchNetwork } from '../contracts/functions';
import { abi } from '../../utils/abi';
import { CRPlayParams } from '../../utils/crplay';

export const AppContext = createContext(0);

const { formatEther } = ethers.utils;

export const AppProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState();
  const [wallet, setWallet] = useState('');
  const [connection, setConnection] = useState();
  const [balance, setBalance] = useState(0);
  const [balanceCRPLAY, setBalanceCRPLAY] = useState(0);
  const [signer1, setSigner] = useState({});
  const [network, setNetwork] = useState();
  const [user, setUser] = useState();
  const [buttonText, setButtonText] = useState('Conectar Carteira');

  const [opacity, setOpacity] = useState(1);

  function layoutHeader(status) {
    setOpacity(status);
  }

  function getBalanceOfCRPLAY(provider, wallet, network) {
    const contractCR = readContract(provider, CRPlayParams[network]['contract'], abi);
    return getBalanceOf(contractCR, wallet);
  }

  function SwitchNetwork(provider, chain) {
    return switchNetwork(provider, chain);
  }

  const priceCoin = localStorage.getItem('price');

  useEffect(() => {
    const request = () => {
      updatePrice();
      getUpdatedPrice();
    };

    request();
  }, []);

  const updatePrice = () => {
    setInterval(() => {
      getUpdatedPrice();
    }, 60000 * 2);
  };

  const getUpdatedPrice = () => {
    // const responseETH = getPrice("ETH");
    // const responseMatic = getPrice("Matic");
    // Promise.all([responseETH, responseMatic]).then(function (result) {
    //     localStorage.setItem(
    //         "price",
    //         JSON.stringify({
    //             ETH: result[0],
    //             MATIC: result[1],
    //         }),
    //     );
    // });
  };

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        appName: 'multiple-contexts-web3modal', // Required
        
        rpc: {
            137: "https://rpc-mainnet.maticvigil.com/",
            80001: "https://rpc-mumbai.maticvigil.com/",
        },
        infuraId: "3ac68be0e4f44833bce00d483f3bd104",
        qrcodeModalOptions: {
            mobileLinks: [
                "metamask",
                "argent",
                "trust",
                "imtoken",
                "pillar",
            ]
        },
      }
    },
  };

  const connectWallet = async () => {
    const web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions,
      projectId: 'a0c35e0c1bfeba86725f907c30474059',
    });

    try {
      const connection = await web3Modal.connect();
      setButtonText('Conectando...');
      const provider = new ethers.providers.Web3Provider(connection, 'any');

      const signer = provider.getSigner(0);
      setSigner(signer);

      const address = await signer.getAddress();
      const balance = await provider.getBalance(address);
      const network = await provider.getNetwork();
      console.log(network.chainId);
      setNetwork(network.chainId);

      if (network.chainId != 137 && network.chainId != 80001) {
        await SwitchNetwork(connection, process.env.REACT_APP_CHAIN);
      }

      try {
        const balanceCR = await getBalanceOfCRPLAY(provider, address, network.chainId);
        let numero = balanceCR;
        let resultado = numero / Math.pow(10, 10);
        resultado = resultado.toFixed(5);
        console.log(resultado);
        setBalanceCRPLAY(formatEther(resultado));
      } catch (e) {
        console.log(e);
      }

      User.list([{ name: 'wallet', operator: '==', value: address }]).subscribe(
        (res) => {
          if (res && res.length > 0) {
            setUser(res[0]);
          } else {
            setUser(undefined);
          }
        }
      );

      setWallet(address);
      setConnection(connection);
      setProvider(provider);
      setBalance(formatEther(balance));
      return {
        address,
        signer,
        user,
        provider,
        connection,
      };
    } catch (error) {
      console.error('error', error);
    }
  };

  const disconnectWallet = () => {
    localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
    setButtonText('Conectar Carteira');
    setWallet('');
    setProvider(undefined);
    setBalance(0);
  };

  useEffect(() => {
    if (connection && provider) {
      connection.on('accountsChanged', async ([currentAccount]) => {
        console.log('accountsChanged: ', currentAccount);
        const balance = await provider.getBalance(currentAccount);
        setWallet(currentAccount);
        setBalance(formatEther(balance));
        
        try {
          const balanceCR = await getBalanceOfCRPLAY(provider, currentAccount, network);
          setBalanceCRPLAY(formatEther(balanceCR));
        } catch(e) {
          console.log(e);
        }
        
        // const creator = await getCreator(currentAccount);
        // console.log("Obtido creator do server: ", creator);
        // if (Object.prototype.hasOwnProperty.call(creator, "id")) {
        //     setCreator(creator);
        // } else {
        //     await createCreator(currentAccount);
        //     const newCreator = await getCreator(currentAccount);
        //     setCreator(newCreator);
        // }
      });
      connection.on('chainChanged', async (chain) => {
        console.log(chain);
        const network = Web3.utils.hexToNumber(chain);
        setNetwork(network);
        const balance = await provider.getBalance(wallet);
        setWallet(wallet);
        setBalance(formatEther(balance));
        const balanceCR = await getBalanceOfCRPLAY(provider, wallet, network);
        setBalanceCRPLAY(formatEther(balanceCR));
      });

      connection.on('connect', (info) => {
        console.log(info);
      });

      connection.on('disconnect', (error) => {
        console.log(error);
      });
      cancelLoading();
    }

    if (!connection && !provider) {
      cancelLoading();
    }
  }, [connection, provider]);

  useEffect(() => {
    const local = localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER');

    if (local !== null) {
      setLoading(true);
      connectWallet().then(() => cancelLoading());
    }
  }, []);

  const cancelLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  if (loading) return <Loading />;

  return (
    <AppContext.Provider
      value={{
        // creator,
        network: network,
        wallet: wallet,
        buttonText: buttonText,
        provider: provider,
        balance: balance,
        balanceCRPLAY: balanceCRPLAY,
        signer: signer1,
        opacity,
        user: user,
        connection: connection,
        priceCoin: JSON.parse(priceCoin),
        layoutHeader,
        setUser: setUser,
        connectWallet: connectWallet,
        disconnectWallet: disconnectWallet,
        SwitchNetwork: SwitchNetwork
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
