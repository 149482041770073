import { Content, FilterContainer, OptionsInput, SelectInput } from './styles';
import CardCollection from '../../components/CardCollection';
import RangePrice from '../../components/RangePrice';
import { useCollection } from 'web3/providers/CollectionContext';
import { useState } from 'react';
import CheckboxFilter from './Components/CheckboxFilter';
import { useEffect } from 'react';
import { formatString } from 'utils/utils';

export const Explorer = () => {
  const { categories, collections } = useCollection();
  const [filteredCollections, setFilteredCollections] = useState(collections);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  const [data, setData] = useState(collections);

  console.log("EXPLORER -> ", collections);

  const handleCategorySelect = (isChecked, key) => {
    let categoryKeys;
    if (isChecked) {
      categoryKeys = [...selectedKeys, key];
      setSelectedKeys(categoryKeys);
    } else {
      categoryKeys = selectedKeys.filter((categoryKey) => categoryKey !== key);
      setSelectedKeys(categoryKeys);
    }
    console.log('New Array: ', categoryKeys);

    if (categoryKeys?.length > 0) {
      setData(filterCollectionsByCategoryKeys(categoryKeys));
      setFilteredCollections(filterCollectionsByCategoryKeys(categoryKeys));
    } else {
      setData(collections);
      resetFilter()
    }
  };

  const filterCollectionsByCategoryKeys = (categoryKeys) => {
    console.log("collection EXPLORER", collections);
    return collections.filter((collection) => {
      return categoryKeys.includes(collection?.category?.key);
    });
  };

  const handleSelectChange = (event) => {
    if (filteredCollections) {
      setSelectedOption(event.target.value);

      if (event.target.value === 'asc') {
        const sortedAscending = [...filteredCollections].sort((a, b) => a.price - b.price);
        setData(sortedAscending);
      } else if (event.target.value === 'desc') {
        const sortedDescending = [...filteredCollections].sort((a, b) => b.price - a.price);
        setData(sortedDescending);
      }
      resetFilter()
    } else {
      setSelectedOption(event.target.value);

      if (event.target.value === 'asc') {
        const sortedAscending = [...collections].sort((a, b) => a.price - b.price);
        setData(sortedAscending);
      } else if (event.target.value === 'desc') {
        const sortedDescending = [...collections].sort((a, b) => b.price - a.price);
        setData(sortedDescending);
      }
      resetFilter()
    }
  };

  const resetFilter = () => {
    // setSelectedKeys([])
    // setData([])
    setSelectedOption()
  }

  return (
    <Content>
      <div className='container'>
        <div className='row g-4'>
          <div className='col-12 col-xl-3'>
            <div className='card mb-4'>
              <div className='card-header'>
                <h4 className='mb-0 fw-bold py-2'>Categorias</h4>
              </div>
              <div className='card-body'>
                {categories.map((category) => (
                  <CheckboxFilter
                    key={category.key}
                    label={formatString(category.label)}
                    isChecked={selectedKeys.includes(category.key)}
                    onChange={(isChecked) =>
                      handleCategorySelect(isChecked, category.key)
                    }
                  />
                ))}
              </div>
            </div>

            {/* <div className='card mb-4'>
              <div className='card-header'>
                <h4 className='mb-0 fw-bold py-2'>Preço</h4>
              </div>
              <div className='card-body px-4'>
                <RangePrice />
              </div>
            </div> */}
          </div>

          <div className='col-12 col-xl-9'>
            <FilterContainer>
              <p style={{ color: "#FFF", marginTop: "20px" }}>Ordenar por:</p>
              <SelectInput value={selectedOption} onChange={handleSelectChange} placeholder='Ordenar por:'>
                <option value="asc">Menor preço</option>
                <option value="desc">Maior preço</option>
              </SelectInput>
            </FilterContainer>
            {/* <ul>
              {data.map(item => (
                <li key={item.id}>{item.name}</li>
              ))}
            </ul> */}
            <div className='row g-4'>
              {data.map((collection) => (
                <div
                  key={'container-collection-' + collection.id}
                  className='col-12 col-md-6 col-xl-4'
                >
                  <CardCollection
                    collection={collection}
                    key={'collection-' + collection.id}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};
