import FormProfile from './components/FormProfile';
import { ProfileContent } from './styles';
import EmptyMessage from 'components/EmptyMessage/EmptyMessage';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCollection } from 'web3/providers/CollectionContext';
import { ListNFT } from 'components/ListNFT';
import { getAll } from 'services/firestore.service';
import { useApp } from 'web3/providers/AppContext';

export const Profile = () => {
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { wallet } = useApp();

  const getUserNft = async () => {
    setIsLoading(true);
    const data = await getAll("nfts")
    const nftUser = data.filter((nft) => nft.walletAddress === wallet);
    setNfts(nftUser)
    setIsLoading(false);
  }

  useEffect(() => {
    getUserNft();
  }, [])

  return (
    <ProfileContent>
      {/* <div className="text-center bg-primary-opacity bg-gradient py-5 justify-content-center d-flex">
                <h1 className="text-uppercase mb-0 fw-bolder">
                    Meu perfil
                </h1>
            </div> */}

      <div className='container my-5'>
        <div className='d-flex justify-content-center mt-5'>
          <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link active'
                id='pills-profile-tab'
                data-bs-toggle='pill'
                data-bs-target='#pills-profile'
                type='button'
                role='tab'
                aria-controls='pills-profile'
                aria-selected='false'
              >
                <i className='bi bi-person fs-5' /> Perfil
              </button>
            </li>
            <li className='nav-item' role='presentation'>
              <button
                className='nav-link'
                id='pills-nfts-tab'
                data-bs-toggle='pill'
                data-bs-target='#pills-nfts'
                type='button'
                role='tab'
                aria-controls='pills-nfts'
                aria-selected='false'
              >
                <i className='bi bi-person fs-5' /> Meus NFT's
              </button>
            </li>
          </ul>
        </div>
        <div className='tab-content' id='pills-tabContent'>
          <div
            className='tab-pane fade show active'
            id='pills-profile'
            role='tabpanel'
            aria-labelledby='pills-profile-tab'
          >
            <FormProfile />
          </div>
        </div>
        <div className='tab-content' id='pills-tabContent'>
          <div
            className='tab-pane fade'
            id='pills-nfts'
            role='tabpanel'
            aria-labelledby='pills-nfts-tab'
          >
            {isLoading && (
              <p>Carregando</p>
            )}
            {nfts?.length > 0 ? (
              <ListNFT nfts={nfts} />
            ) : (
              <EmptyMessage message="Você ainda não possui NFT's adquiridos" />
            )}
          </div>
        </div>
      </div>
    </ProfileContent>
  );
};
