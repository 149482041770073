import React from 'react';
import { Transition } from 'react-transition-group';

const DefaultAnimation = ({
  children,
  timeout = 300,
  classNames,
  nodeRef,
  show = false,
}) => {
  const defaultStyle = {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <Transition
      nodeRef={nodeRef}
      in={!!children || !show}
      timeout={timeout}
      classNames={classNames}
    >
      {(state) => (
        <div className={`animation ${classNames}-${state}`}>{children}</div>
      )}
    </Transition>
  );
};

export default DefaultAnimation;
