import React, { useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import './Autocomplete.scss';
import { useCollection } from 'web3/providers/CollectionContext';
import DefaultAnimation from 'components/DefaultAnimation/DefaultAnimation';
import { useNavigate } from 'react-router-dom';

const Autocomplete = ({}) => {
  const navigate = useNavigate();
  const getSuggestionValue = (suggestion) => suggestion.name;

  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const { collections, getNfts } = useCollection();
  const nodeRef = useRef(null);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
      return [];
    }

    const regex = new RegExp(`\\b${inputValue}`, 'gi');
    return collections.filter((collec) => regex.test(collec.name));
  };

  const onChange = (event, { newValue }) => {
    console.log('onChange: ', newValue);
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    // Adicione aqui a ação que deseja executar ao clicar na sugestão
    console.log('Sugestão selecionada:', suggestion);
    navigate(`/collection/${suggestion.contract}`);
    setValue('');
  };
  const inputProps = {
    placeholder: 'Pesquisar coleções',
    value,
    onChange,
  };

  const renderSuggestion = (collection) => {
    return (
      <DefaultAnimation timeout={600} classNames={'container-suggestion'}>
        <div
          className={`container-suggestion ${
            suggestions?.length > 0 ? 'open' : 'close'
          }`}
          ref={nodeRef}
        >
          <div className='left'>
            <div
              className='image'
              style={{ backgroundImage: `url(${collection.image})` }}
              alt='Imagem coleção'
            />
            <div className='left2'>
              <p className='title'>{collection.name}</p>
              <p className='description'>
                {getNfts(collection.contract)?.length || 0} itens
              </p>
            </div>
          </div>
          <div className='right'>
            <p className='description'>
              {collection.price.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              MATIC
            </p>
          </div>
        </div>
      </DefaultAnimation>
    );
  };
  return (
    <div
      className={`container-suggest ${
        suggestions?.length > 0 ? 'fadeIn' : 'fadeOut'
      }`}
    >
      <DefaultAnimation timeout={500} classNames={'pure-suggest'}>
        <Autosuggest
          // alwaysRenderSuggestions={true}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={onSuggestionSelected}
        />
      </DefaultAnimation>
    </div>
  );
};

export default Autocomplete;
