import styled from 'styled-components';

export const Content = styled.div `
    background-color: ${({theme}) => theme.bgdefault};
    color: ${({theme}) => theme.color};
    padding: 5em 0;
    
    .card{
        background-color: rgba(19,9,51,1);
    }

    
`;
