import Styled from 'styled-components'

export const Content = Styled.div`

    padding-bottom: 5rem;

    .banner-collection {
        position: relative;
        display: grid;
    }
    
    .banner-collection img {
        width: 100%;
        height: 400px;
        object-fit: cover;
    }
    
    .image-collection {
        margin-top: -160px;
        position: absolute;
    }
    
    .image-collection img {
        width: 200px;
        height: 200px;
        border-radius: 20px;
        border: 6px solid #FFFFFF;
    }
    
    .icons {
        font-size: 20px;
        display: flex;
        gap: 30px;
    }
    
    .icons a {
        text-decoration: none;
        color: #fff;    
    }

    .link_contract {
        text-decoration: none;
        color: white;
    }

    .creator-link{
        color: #ff4f81;
    }
    
    
    
`;