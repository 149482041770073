import React, { useState, useEffect } from 'react';
import { Content } from './styles';
import { CardNFT } from '../CardNFT';
import { ButtonShowMore } from 'components/Collections/styles';

export const ListNFT = ({ nfts, collection }) => {
  const initialItemsToShow = 8;
  const [visibleNfts, setVisibleNfts] = useState([]);

  useEffect(() => {
    setVisibleNfts(nfts.slice(0, initialItemsToShow));
  }, [nfts]);

  const handleLoadMoreClick = () => {
    const itemsPerPage = 4;
    const endIndex = Math.min(visibleNfts.length + itemsPerPage, nfts.length);
    setVisibleNfts(nfts.slice(0, endIndex));
  };

  return (
    <Content>
      <div className='row g-4'>
        <div className='col-12'>
          <div className='row g-4'>
            {visibleNfts?.map((nft) => {
              if (nft) {
                return (
                  <div
                    key={'container-card-nft' + nft?.id}
                    className='col-12 col-sm-6 col-md-4 col-xl-3'
                  >
                    <CardNFT key={'card-nft-' + nft?.id} nft={nft} collection={collection} />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          {visibleNfts.length < nfts.length && (
            <div className='text-center mt-4'>
              <ButtonShowMore onClick={handleLoadMoreClick} style={{ marginTop: "100px" }}>
                Ver mais
              </ButtonShowMore>
            </div>
          )}
        </div>
      </div>
    </Content>
  );
};
