import styled from 'styled-components';

export const CardContent = styled.div`
  .card {
    background-color: rgba(19,9,51,1) !important;
    border-radius: 20px;
  }

  .myBadge {
    transform: rotate(320deg);
    position: absolute;
    left: 4px;
    top: 18px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    filter: grayscale(0.2);
  }

  /* .selled {
    filter: grayscale(0.3);
  } */

  .card .avatar-creator {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 50%;
  }

  .card .avatar-creator span {
    font-size: 1.2rem;
  }

  .avatar-creator img {
    border-radius: 50%;
    object-fit: cover;
    width: 2.5em;
    height: 2.5em;
  }

  .card-image img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    border: 2px solid #ffffff;
  }
`;

export const CardList = styled.div`
  background-color: ${({ theme }) => theme.bg300};
  border-radius: 20px;
  display: flex;
  gap: 25px;

  .card-image {
    width: 180px;
    height: 180px;
    background-color: #e6e6e6;
    border-radius: 20px;
  }

  .card-image img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border: 2px solid #ffffff;
  }

  .card-content {
    margin: 20px 0;
  }
`;
