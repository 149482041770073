export const CRPlayParams = {
    "137": {
      contract: "0x5C742c8BEB1fE8C105FCA6462A70E31A2d244a5C",
      endpoint: "https://polygon-mainnet.infura.io/v3/",
      url: "https://polygon-mainnet.infura.io/v3/3ac68be0e4f44833bce00d483f3bd104",
      scan: "https://polygonscan.com/tx/",
      address: "https://polygonscan.com/address/",
      chainId: 137
    },
    "80001": {
      contract: "0xe1A917672a9DD2a54d2f5D26974cC8D98aB4e1f1",
      endpoint: "https://polygon-mumbai.infura.io/v3/",
      url: "https://polygon-mumbai.infura.io/v3/3ac68be0e4f44833bce00d483f3bd104",
      scan: "https://mumbai.polygonscan.com/tx/",
      address: "https://mumbai.polygonscan.com/address/",
      chainId: 80001
    }
  };