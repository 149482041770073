import React, { useState } from 'react';
import { A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

import { ContentControl } from './styles';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardCollection from '../CardCollection';

export const Carousel = ({ collections }) => {
  const [swiper, setSwiper] = useState(null);
  const visibleCollections = collections?.slice(0, 5);

  const handlePrevClick = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  console.log("collections -------> ", collections);

  return (
    <ContentControl>
      <Swiper
        modules={[Navigation, A11y]}
        loop={false}
        spaceBetween={30}
        slidesPerView={4}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1199: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        onSlideChange={() => { }}
        onSwiper={(swiper) => { }}
      >
        <div className='buttons-swiper'>
          <div className='swiper-button-next' onClick={handleNextClick}>
            <FontAwesomeIcon icon={faCaretRight} size='2x' />
          </div>
          <div className='swiper-button-prev' onClick={handlePrevClick}>
            <FontAwesomeIcon icon={faCaretLeft} size='2x' />
          </div>
        </div>

        {visibleCollections?.map((collection) => (
          <SwiperSlide key={collection.id}>
            <CardCollection collection={collection} />
          </SwiperSlide>
        ))}
      </Swiper>
    </ContentControl>
  );
};
