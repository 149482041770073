import styled from "styled-components";

export const LoginAdminContainer = styled.div`
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginAdminContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 400px;
  background-color: ${({ theme }) => theme.bg200};
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
  padding: 35px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  border-top: 4px solid orange;
`;

export const ContainerButton = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;

`;

export const Input = styled.input`
  background: #FFF;
  border: 2px solid #79D7ED;
  outline: none;
  width: 100%;
  padding: 18px;
  border-radius: 10px;
`;

export const ButtonLogin = styled.button`
  width: 100%;
  padding: 15px;
  background-color: ${({ theme }) => theme.bgdefault};
  outline: none;
  border: none;
  border-radius: 10px;
  color: #FFF;
`;