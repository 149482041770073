import styled from 'styled-components';

export const Content = styled.div`
  background-color: ${({ theme }) => theme.bgdefault};
  color: ${({ theme }) => theme.color};

  .tab-content {
    transition: all 0.2s ease-in-out;
  }

  .tab-pane {
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  .tab-pane.show,
  .tab-pane.active{
    opacity: 1;
  }
`;

export const ButtonShowMore = styled.button`
  width: 240px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: none;
  border-radius: 30px;
  font-size: 20px;
  font-weight: bold;
`;
