import { useApp } from 'web3/providers/AppContext';
import { truncateAddress } from 'utils/utils';

import { useEffect, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { Content, ListMenuSideBar } from './styles';
import { NavLink } from 'react-router-dom';
import './SideBarAccount.scss';

import CRPLAY from 'assets/img/crplay.png';

const SideBarAccount = () => {
  const { wallet, balance, balanceCRPLAY, user } = useApp();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [textCopy, setTextCopy] = useState('Copiar');
  const [customName, setCustomName] = useState('');

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    console.log('Usuário logado: ', user);
    if (user && user.name) {
      const names = user.name.split(' ');
      if (names && names.length > 2) {
        setCustomName(names[0] + " " + names[1]);
      } else {
        setCustomName(names.join(' '));
      }
    }
  }, [user]);

  const copyAddress = (text) => {
    setTextCopy('Copiado');
    setTimeout(() => {
      setTextCopy('Copiar');
    }, 500);

    navigator.clipboard.writeText(text);
  };

  return (
    <Content>
      <div
        className='offcanvas offcanvas-end'
        tabIndex='-1'
        id='sidebarAccount'
        aria-labelledby='offcanvasRightLabel'
      >
        <div className='offcanvas-body p-0'>
          <div className='d-flex align-items-center justify-content-between border-bottom p-4'>
            <Tooltip
              placement='top'
              isOpen={tooltipOpen}
              target={'Tooltip-copy'}
              toggle={toggle}
            >
              {textCopy}
            </Tooltip>

            <span
              className=''
              id='Tooltip-copy'
              role='button'
              onClick={() => copyAddress(wallet)}
            >
              {truncateAddress(wallet)} <i className='fa-solid fa-copy' />
            </span>
            <span role='button' data-bs-dismiss='offcanvas' aria-label='Close'>
              <i className='bi bi-x fs-1' />
            </span>
          </div>

          <div className='px-2'>
            {user && customName ? (
              <div className='card card-profile mt-3'>
                <ul className='list-group'>
                  <div className='p-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='left'>
                        <div
                          className='image'
                          style={{
                            backgroundImage: `url(${user?.profile_avatar})`,
                          }}
                          alt='Imagem Perfil do Usuário'
                        />
                        <div className='left-bottom'>
                          <p className='title'>{customName}</p>
                          <p className='description'>
                            {user?.username || user?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            ) : null}

            <div className='card mt-3'>
              <ul className='list-group list-group-flush '>
                <li className='list-group-item py-3'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <i className='fa fa-wallet fs-3' />
                    <span> {parseFloat(balance).toFixed(4)} MATIC</span>
                  </div>
                </li>
                <li className='list-group-item py-3'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <img src={CRPLAY} width="26" alt="CRPLAY logo" className="img-fluid d-inline" />
                    <span> {parseFloat(balanceCRPLAY).toFixed(4)} CRPLAY</span>
                  </div>
                </li>
              </ul>
            </div>

            {wallet && (
              <div className='my-4'>
                <ListMenuSideBar>
                  <li data-bs-dismiss='offcanvas'>
                    <NavLink to='/profile'>
                      <i className='bi bi-person-circle' />
                      <span>Meu perfil</span>
                    </NavLink>
                  </li>
                  {/* <li data-bs-dismiss='offcanvas'>
                    <NavLink to='/profile'>
                      <i className='bi bi-person-circle' />
                      <span>Meus NFT's</span>
                    </NavLink>
                  </li> */}
                </ListMenuSideBar>
              </div>
            )}
          </div>
        </div>
      </div>
    </Content>
  );
};
export default SideBarAccount;
