import styled from 'styled-components';

export const Content = styled.div`
    background-color: ${({ theme }) => theme.bgdefault};
    padding: 5em 0;
    
    .card {
        background-color: ${({ theme }) => theme.bg200};
        border-radius: 20px;
    }

    .form-check-input {
        width: 1.4em;
        height: 1.4em;
        background-color: ${({ theme }) => theme.bg};
    }
`;

export const FilterContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    background-color: ${({ theme }) => theme.bg200};
    border-radius: 10px;
    gap: 10px;
`;

export const SelectInput = styled.select`
    outline: none;
    border-radius: 5px;
    background: #ffff;
    color: #000;
    width: 200px;
    padding: 10px;
`;