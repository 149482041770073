import styled from 'styled-components';

export const UniswapContent = styled.div `
    
    .iRWSZg{
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .dZAkss{
        color: #fff;
        margin-left:0;
        margin-right:0;
    }
    .RGxvm{
        height: auto;
        display: flex;
        align-items: center;
    }
    .OYwcU,
    .cYuIKx{
        border-radius: 16px;
    }
    .bleZrU{
        border-radius: 50px;
        background-color: #007cb5;
        color: #fff;
    }
    .cjJrRy{
        margin: 10px 0;
        background-color: #fff;
        border-radius: 16px;
    }

`;