import styled from 'styled-components';


export const Content = styled.div `
    background-color: #161616;
`;

export const ContentControl = styled.div `
    .swiper {
        padding-top: 100px;
    }
           
    .buttons-swiper {
        position: absolute;
        top: 0;
        display: flex;
        height: 50px;
        margin: 20px 0;
    }

    .swiper-button-next, .swiper-button-prev {
      position: relative;
      width: 3em;
      height: 3em;
      margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({theme}) => theme.bgsecondary};
      border-radius: 50px;
      color: #fff;
    }
    
    .swiper-button-next:after, .swiper-button-prev:after {
      font-size: 20px;
      padding: 5px 10px 5px 12px;
      border-radius: 50px;
    }
    
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
      left: auto;
      right: 40px;
    }
    
    .swiper-button-next, .swiper-rtl .swiper-button-prev {
      right: -70px;
    }
       
    .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
      content: none;
      content-align: center;
      color: #202020;
    }
    
    .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
      content: none;
      color: #202020;
    }
`;
