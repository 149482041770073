import contractJson from "../data/voluhpia.json";
import Web3 from "web3";
import Swal from "sweetalert2";
import { swalCustom } from "../../components/SweetAlert";
import { ethers } from "ethers";
import keccak256 from "keccak256";
import { MerkleTree } from "merkletreejs";
import { networkParams } from "../networks/networks";


export const providerReader = network => {
    return new ethers.providers.InfuraProvider(network, "3ac68be0e4f44833bce00d483f3bd104");
};

export const readContract = (provider, address, abi = contractJson.abi) => {
    console.log("address readContract: ", address);
    return new ethers.Contract(address, abi, provider);
};

export const getTotalSupply = async contract => {
    return contract
        .totalSupply()
        .then(totalSupply => {
            return totalSupply.toNumber();
        })
        .catch(error => {
            console.log("error totalSupply", error);
            return 0;
        });
};

export const getMaxSupply = async contract => {
    return contract
        .getMaxSupply()
        .then(maxSupply => {
            return maxSupply.toNumber();
        })
        .catch(error => {
            console.log("error getMaxSupply", error);
            return 0;
        });
};

export const getNftEtherValue = async contract => {
    return contract
        .price()
        .then(etherValue => {
            return ethers.utils.formatEther(etherValue);
        })
        .catch(error => {
            console.log("error getNftEtherValue", error);
            return 0;
        });
};

export const getName = async contract => {
    return contract
        .name()
        .then(name => {
            return name;
        })
        .catch(error => {
            console.log("error name", error);
            return 0;
        });
};

export const contractPaused = async contract => {
    return contract
        .paused()
        .then(isPaused => {
            return isPaused;
        })
        .catch(error => {
            console.log("error paused", error);
            return true;
        });
};

export const switchNetwork = async (provider, chain) => {
    try {
        await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: Web3.utils.toHex(chain)}],
        }).then(async function (res) {
            console.log("Troca de rede", res);
            if (res == null) {
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: String(networkParams[chain].hexaChainId),
                                chainName: networkParams[chain].network_name,
                                nativeCurrency: {
                                    name: networkParams[chain].crypto,
                                    symbol: networkParams[chain].crypto,
                                    decimals: 18,
                                },
                                rpcUrls: [networkParams[chain].rpc_urls],
                                blockExplorerUrls: [networkParams[chain].block_explorer_urls],
                            },
                        ],
                    }).then(function (success){
                        console.log(success);
                    }).catch(function (error) {
                        console.log(error);
                    });
                } catch (error) {
                    console.log(error);
                    return false;
                }
            }
        }).catch(async function (error) {
            try {
                await provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: networkParams[chain].hexaChainId,
                            chainName: networkParams[chain].network_name,
                            nativeCurrency: {
                                name: networkParams[chain].crypto,
                                symbol: networkParams[chain].crypto,
                                decimals: 18,
                            },
                            rpcUrls: [networkParams[chain].rpc_urls],
                            blockExplorerUrls: [networkParams[chain].block_explorer_urls],
                        },
                    ],
                }).then(function (success){
                    console.log(success);
                }).catch(function (error) {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
                return false;
            }
        });
        console.log("You have switched to the right network")

        return new ethers.providers.Web3Provider(provider, "any")

    } catch (e) {
        try {
            await provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                {
                    chainId: networkParams[chain].hexaChainId,
                    chainName: networkParams[chain].network_name,
                    nativeCurrency: {
                        name: networkParams[chain].crypto,
                        symbol: networkParams[chain].crypto,
                        decimals: 18,
                    },
                    rpcUrls: [networkParams[chain].rpc_urls], 
                    blockExplorerUrls: [networkParams[chain].block_explorer_urls],
                },
                ],
            });
        } catch (error) {
            return false;
        }
    }
};

export const mintNft = async (contract, account, amount, value) => {
    try {
        const tx = await contract.mint(amount, account, {
            from: account,
            value: Web3.utils.toWei(value, "ether"),
        });

        const response = await tx.wait();
        console.log(response);
        return response;
    } catch (error) {
        return -1;
    }
};

export const MintCollection = async (contract, account, value, cid, gasPrice, promo, priceScreen) => {
    try {
        if (promo == true) {
            var tx = await contract.mintPromo(account, cid, {
                from: account,
                value: Web3.utils.toWei(String(priceScreen), "ether"),
                gasPrice: gasPrice,
            });
        } else {
            var tx = await contract.mint(account, cid, {
                from: account,
                value: Web3.utils.toWei(String(value), "ether"),
                gasPrice: gasPrice,
            });
        }
        
        const response = await tx.wait();
        console.log(response);
        return response;
    } catch (error) {
        console.log(error.code);
        return error;
    }
};

export const SetContractUri = async (contract, ipfs, gasPrice) => {
    try {
        const tx = await contract.setContractURI(ipfs, { gasPrice: gasPrice });

        const response = await tx.wait();
        console.log(response);
        return response;
    } catch (error) {
        console.log(error);
        return error.code;
    }
};

export const isWhitelisted = async (contract, wallet, proof) => {
    return contract
        .isWhitelisted(wallet, proof)
        .then(isPaused => {
            return isPaused;
        })
        .catch(error => {
            console.log("error paused", error);
            return true;
        });
};

export const AddTokenMetamask = async (contract, symbol, image) => {
    const tokenDecimals = 0;

    try {
        const wasAdded = await window.ethereum
            .request({
                method: "wallet_watchAsset",
                params: {
                    type: "ERC20",
                    options: {
                        address: contract,
                        symbol: symbol,
                        decimals: tokenDecimals,
                        image: image,
                    },
                },
            })
            .then(function (success) {
                Swal.close();
                console.log("success", success);
            })
            .catch(e => console.log("error", e));

        if (wasAdded) {
            window.ethereum.on("close", function (accounts) {});
        } else {
            console.log(wasAdded);
        }
    } catch (error) {
        Swal.close();
        swalCustom.fire("Ops!", "erro ao adicionar token na carteira", "error");
    }
};

export const generateHash = (addresses, address) => {
    const leaves = addresses.map(x => keccak256(x));

    const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });

    const buf2hex = x => "0x" + x.toString("hex");
    const leaf = keccak256(address);
    const proof = tree.getProof(leaf).map(x => buf2hex(x.data));

    return proof;
};

// import contractJson from "../data/voluhpia.json";
// //import Web3 from "web3";
// import {networkParams} from "../networks/networks";
// import Swal from "sweetalert2";
// import {swalCustom} from "../../components/SweetAlert";
// import { ethers } from "ethers";

// export const providerReader = (network) => {
//     return new ethers.providers.InfuraProvider(
//         network,
//         "3ac68be0e4f44833bce00d483f3bd104"
//     );
// }

// export const readContract = (provider, abi = contractJson.abi, address = contractJson.address) => {
//     return new ethers.Contract(address, JSON.parse(abi), provider)
// }

export const getBalanceOf = async (contract, account) => {
    return contract.balanceOf(account)
        .then((balance) => {
            console.log("balance function", balance.toString());
            return balance
        })
        .catch((error) => {
            console.log('error balanceOf', error)
            return 0;
        });
}

// export const getTotalSupply = async (contract) => {
//     return contract.totalSupply()
//         .then((totalSupply) => {
//             return totalSupply.toNumber()
//         })
//         .catch((error) => {
//             console.log('error totalSupply', error)
//             return 0;
//         });
// }

// export const getMaxSupply = async (contract) => {
//     return contract.maxSupply()
//         .then((maxSupply) => {
//             return maxSupply.toNumber()
//         })
//         .catch((error) => {
//             console.log('error maxSupply', error)
//             return 0;
//         });
// }

// export const getCost = async (contract) => {
//     return contract.cost()
//         .then((cost) => {
//             return ethers.utils.formatEther(cost)
//         })
//         .catch((error) => {
//             console.log('error cost', error)
//             return 0;
//         });
// }

// export const getName = async (contract) => {
//     return contract.name()
//         .then((name) => {
//             return name
//         })
//         .catch((error) => {
//             console.log('error name', error)
//             return 0;
//         });
// }

// export const contractPaused = async (contract) => {
//     return contract.paused()
//         .then((isPaused) => {
//             return isPaused
//         })
//         .catch((error) => {
//             console.log('error paused', error)
//             return true;
//         });
// }

// export const switchNetwork = async () => {
//     const chain = 80001;

//     try {
//         // await library.provider.request({
//         // await window.ethereum.request({
//         //     method: "wallet_switchEthereumChain",
//         //     // params: [{ chainId: toHex(chain) }]
//         //     params: [{ chainId: Web3.utils.toHex(chain) }]
//         // });

//         return true;

//     } catch (switchError) {
//         if (switchError.code === 4902) {
//             try {
//                 // await window.ethereum.request({
//                 //     method: "wallet_addEthereumChain",
//                 //     params: [networkParams[Web3.utils.toHex(chain)]]
//                 // });
//             } catch (error) {
//                 console.log(error);
//             }
//         }
//     }

// };

// export const AddTokenMetamask = async () => {

//     const tokenSymbol = 'VSC';
//     const tokenDecimals = 0;
//     const tokenImage = 'https://i.seadn.io/gae/Zo8XBNd1yq5zSprfoDHZehJmuBm2MA-v7i-2wyNKgqABYnBiOqTbLRhJlbHZ5SiI1vW_IECRMhRFllsZItiO_REPysU3aJGEp0J9LQ?auto=format&w=1920';

//     var con = '0x03b1FfA4EB358e71A8ba41761a9D076d72D7f9c6';

//     try {
//         const wasAdded = await window.ethereum.request({
//             method: 'wallet_watchAsset',
//             params: {
//                 type: 'ERC20',
//                 options: {
//                     address: con,
//                     symbol: tokenSymbol,
//                     decimals: tokenDecimals,
//                     image: tokenImage,
//                 },
//             },
//         }).then(function (success) {
//             Swal.close();
//             console.log('success', success);
//         }).catch(e => console.log('error', e));

//         if (wasAdded) {
//             window.ethereum.on('close', function (accounts) { })
//         } else {
//             console.log(wasAdded)
//         }
//     } catch (error) {
//         Swal.close();
//         swalCustom.fire('Ops!', 'erro ao adicionar token na carteira', 'error');
//     }
// }

export const addTokenMetamask = async (contract, symbol, image, provider) => {
    console.log("addTokenMetamask", contract, symbol, image);
    const tokenDecimals = 0;

    try {
        const wasAdded = await provider.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: contract,
                    symbol: symbol,
                    decimals: tokenDecimals,
                    image: image,
                },
            },
        }).then(function (success) {
            Swal.close();
            console.log('success', success);
            return true
        }).catch(e => console.log('error', e));

        console.log('wasAdded', wasAdded)

        if (wasAdded) {
            //window.ethereum.on('close', function (accounts) { })
            return true
        } else {
            console.log('wasAdded', wasAdded)
        }
    } catch (error) {

        throw new Error('Erro ao adicionar token a carteira');

        //  Swal.close();
       // swalCustom.fire('Ops!', 'erro ao adicionar token na carteira', 'error');
    }
}