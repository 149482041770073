import { Content } from './styles'
import { CardCoin } from "../CardCoin";
import { UniswapSwap } from "../UniswapSwap";

const Coins = () => {
    return (
        <Content>
            <div className="container contents py-5">
                <div className="row">
                    <div className="col-12 col-md-6 order-0">
                        <h6>CRPLAY</h6>
                        <h1 className="fs-1 fw-bolder h1-title">Compre a moeda do jogo pagando com PIX ou MATIC</h1>
                        <div className="mt-5">
                            <p>A moeda do jogo agora pode ser adquirida off-game. Conecte sua carteira e compre agora pagando com PIX ou troque seus MATICS por CRPLAY.</p>
                            <p>Os players da MetaSoul utilizam o CRPLAY para comprar itens dentro do jogo. Os itens digitais adquiridos são de propriedade do comprador, tudo garantido pela segurança e transparência da blockchain. </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 offset-md-2 mt-3 mt-md-0">
                        <CardCoin />
                    </div>
                </div>
                {/* <div className="row justify-content-center">
                    <div className="col-12 col-md-4">
                        <UniswapSwap />
                    </div>
                </div> */}
            </div>
        </Content>
    )
}

export default Coins
