import { SwapWidget, Theme } from '@uniswap/widgets'
import { UniswapContent } from './styles';
import '@uniswap/widgets/fonts.css'
import $ from 'jquery';
import { useApp } from "web3/providers/AppContext";
import { CRPlayParams } from "../../utils/crplay";

export const UniswapSwap = ({modal}) => {

  const {network, wallet, connectWallet, SwitchNetwork, connection} = useApp();

  console.log(network);

  if (wallet == '') {
    modal(false)
    connectWallet();
    return;
  } else {
    if (network != 137 && network != 80001) {
      SwitchNetwork(connection, process.env.REACT_APP_CHAIN);
    } else { 
      console.log("network |-> ", network, CRPlayParams[network].contract);

    const MY_TOKEN_LIST = [
      {
        "name": "CryptoSoul Roleplay",
        "address": CRPlayParams[network].contract,
        "symbol": "CRPLAY",
        "decimals": 10,
        "chainId": CRPlayParams[network].chainId,
        "logoURI": "https://metaexp-mobiup.web.app/static/media/crplay.5afc235793aa70ca3e0b.png"
      }
    ];
  
    const NATIVE = 'NATIVE';
    const CRPLAY = CRPlayParams[network].contract;
  
    setTimeout(() => {
      $('.gQmRok, .dORACQ').hide();
      console.log("gQmRok");
    }, 2500);
    
    const myDarkTheme: Theme = {
      primary: '#000',
      secondary: '#666',
      interactive: '#AFAFAF',
      container: '#DADADA',
      module: '#FFF',
      accent: '#007cb5',
      outline: '#000',
      dialog: '#FFF',
      fontFamily: 'Inter, sans-serif',
      borderRadius: 2,
    }
  
    return (
      <UniswapContent>
        <div className="Uniswap">
          <SwapWidget 
            theme={myDarkTheme}
            tokenList={MY_TOKEN_LIST}
            defaultInputTokenAddress={NATIVE}
            defaultInputAmount={2}
            defaultOutputTokenAddress={CRPLAY}
            width={'100%'} >
          </SwapWidget>
        </div>
      </UniswapContent>
    );
    }
  }
};

export default UniswapSwap;
