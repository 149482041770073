import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck,faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import { useApp } from "web3/providers/AppContext";
import { addTokenMetamask } from "web3/contracts/functions";
import * as S from '../styles'
import { walletFormat } from 'utils/utils';
import { useEffect } from 'react';
import {Link} from "react-router-dom";

export const SuccessStep = ({buttonLabel,changeStep,crypto}) => {

  const { wallet } = useApp();

  useEffect(() => {
    localStorage.removeItem(crypto?.imageId);
  }, []);

return(
    <S.CheckoutContainer>
      <S.AlertDetalis>
      <FontAwesomeIcon icon={faCheck} size='2x' className='check' />
        <p className='success_p'>Seu token estará em sua Wallet dentro de 1 à 3 <br/>minutos!</p>

        <span className='success_span'>Wallet: {walletFormat(wallet)}</span>

        <button onClick={() => addTokenMetamask(crypto?.contract, crypto?.symbol, crypto?.url)}> <FontAwesomeIcon icon={faPlusCircle} size='sm' className='plus' /> Adicionar NFT em sua Metamask</button>

        <p className='alert'>Caso o APP da Metamask não abra automaticamente, abra a Metamask manualmente.</p>
      </S.AlertDetalis>

      <S.MyNFTSButton>
        <Link className="text-white text-decoration-none" to={"/profile"} label="Meu Perfil">Meu Perfil</Link>
      </S.MyNFTSButton>


    <S.CheckoutFooter>
      <a href='https://itspay.io/'> <strong>Powered by</strong>  <img src="https://itsramp.io/itspay_assets/img/ItsPay.png?t=1683717564" alt="" /></a>
    </S.CheckoutFooter>

    </S.CheckoutContainer>
  )

}