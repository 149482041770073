import styled from 'styled-components';

export const Content = styled.div `
    .content-carousel {
        background-color: ${({theme}) => theme.bg100};
    }
    
    .image-nft {
        display: flex;
        width: 100%;
        height: 600px;
        object-fit: cover;
    }
    
`;
