import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import Swal from "sweetalert2";
import { useRef, useState } from "react";
import { BannerContent, PlaceholderBanner, PlaceholderImage, ProfileContent } from "./styles";
import { Formik, Form, Field } from "formik";
import InputMask from "react-input-mask";

import { formSchema, validateName } from "./utils";

import { uploadImage } from "utils/utils";
import { swalCustom } from "components/SweetAlert";
import { useApp } from "../../../../web3/providers/AppContext";
import { User } from "models/user";
import { useEffect } from "react";

const FormCreate = () => {
    const { wallet, user, setUser } = useApp();

    console.log("Usuário logado: ", user);

    const [bannerProfile, setBannerProfile] = useState([]);
    const [imageProfile, setImageProfile] = useState([]);

    const inputFile = useRef(null);
    const inputFileBanner = useRef(null);

    useEffect(() => {
        const subscriptionUser = User.list([{ name: "wallet", operator: "==", value: wallet }]).subscribe(res => {
            if (res && res.length > 0) {
                setUser(res[0]);
            } else {
                setUser(undefined);
            }
        });
        return () => {
            subscriptionUser.unsubscribe();
        };
    }, [setUser, wallet]);

    function handleChangeImageProfile(e) {
        setImageProfile(e.target.files);
    }

    function handleChangeImageBanner(e) {
        setBannerProfile(e.target.files);
    }

    const PopoverInfo = props => {
        const supportedTypes = "JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF.";

        return (
            <UncontrolledPopover placement="bottom" target={props.target} trigger="focus">
                <PopoverBody>
                    <div className="d-grid">
                        <small>
                            Tipos suportados: <b> {props.supported ?? supportedTypes} </b>
                        </small>
                        <small>
                            Tamanho máximo: <b>100 MB</b>
                        </small>
                        <small>
                            Limite de arquivos: <b>{props.limit}</b>
                        </small>
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        );
    };

    const RequireMessage = ({ message }) => <span className="text-danger small"> {message ?? "Preencha este campo"} </span>;

    return (
        <ProfileContent>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: user?.name ?? "",
                    username: user?.username ?? "",
                    biography: user?.biography ?? "",
                    birth_date: user?.birth_date ?? "",
                    site: user?.site ?? "",
                    email: user?.email ?? "",
                    wallet: user?.wallet ? user?.wallet : wallet ? wallet : "",
                    discord: user?.discord ?? "",
                    twitter: user?.twitter ?? "",
                }}
                validationSchema={formSchema}
                onSubmit={async values => {
                    console.log("onSubmit Formik: ", values);

                    Swal.fire({
                        title: "Aguarde...",
                        html: `Processando dados`,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });
                    const folderBucket = "users/" + values.wallet + "/";
                    if (imageProfile?.length > 0) {
                        values["profile_avatar"] = await uploadImage(imageProfile[0], folderBucket, "avatar");
                    }

                    if (bannerProfile?.length > 0) {
                        values["profile_banner"] = await uploadImage(bannerProfile[0], folderBucket, "banner");
                    }

                    console.log("uploadImage: ", values);

                    //Atualizar usuário
                    if (user && user.id) {
                        console.log("Atualizar Usuário: ", user);
                        User.update(user.id, values).subscribe(
                            res => {
                                console.log("Usuário atualizado com sucesso", res);
                                Swal.close();
                                swalCustom.fire({
                                    icon: "success",
                                    title: "Sucesso",
                                    html: `Os dados de usuário foram atualizados.`,
                                    allowOutsideClick: false,
                                });
                            },
                            error => {
                                console.error("Error ao atualizar usuário: ", error);
                                Swal.close();
                                swalCustom.fire({
                                    title: error?.code ? `Error ${error.code}` : "Oops!",
                                    html: error?.message ? error.message : "Falha ao atualizar dados do usuário.",
                                    icon: "error",
                                    confirmButtonText: "Fechar",
                                });
                                return;
                            },
                        );
                    }
                    //Criar usuário
                    else {
                        console.log("criar usuário!!");
                        User.add(values).subscribe(
                            res => {
                                console.log("Usuário criado com sucesso", res);
                                Swal.close();
                                swalCustom.fire({
                                    icon: "success",
                                    title: "Sucesso",
                                    html: `Os dados de usuário foram criados.`,
                                    allowOutsideClick: false,
                                });
                            },
                            error => {
                                console.error("Error ao criar usuário: ", error);
                                Swal.close();
                                swalCustom.fire({
                                    title: error?.code ? `Error ${error.code}` : "Oops!",
                                    html: error?.message ? error.message : "Falha ao criar dados do usuário.",
                                    icon: "error",
                                    confirmButtonText: "Fechar",
                                });
                                return;
                            },
                        );
                    }
                }}>
                {({ errors, touched, values, setFieldValue }) => (
                    <Form>
                        <div className="row mt-5">
                            <div className="col-12 col-lg-4">
                                <div className="card bg-secondary rounded-5">
                                    <div className="card-body bg-secondary rounded-5 py-5 px-4">
                                        <h4>
                                            Imagem do perfil
                                            <Button id="infoCollection" type="button" className="link bg-transparent border-0">
                                                <i className="fa-sharp fa-solid fa-circle-info text-info fs-5 ms-2" />
                                            </Button>
                                        </h4>
                                        <PopoverInfo target="infoCollection" limit="1" />

                                        <div className="image-profile mt-4">
                                            {imageProfile.length > 0 && (
                                                <img src={URL.createObjectURL(imageProfile[0])} className="img-fluid rounded-circle" alt="Imagem do perfil" onClick={() => inputFile.current.click()} />
                                            )}

                                            {!imageProfile.length && user?.profile_avatar && (
                                                <img src={user?.profile_avatar} className="img-fluid rounded-circle" alt="Imagem do perfil" onClick={() => inputFile.current.click()} />
                                            )}

                                            {!imageProfile.length && !user?.profile_avatar && (
                                                <PlaceholderImage onClick={() => inputFile.current.click()}>
                                                    <i className="bi bi-person-fill" />
                                                </PlaceholderImage>
                                            )}
                                        </div>

                                        <div className="mt-5">
                                            <input className="btn btn-primary" type="file" onChange={handleChangeImageProfile} ref={inputFile} />

                                            <button className="btn btn-primary w-100" onClick={() => inputFile.current.click()} type="button">
                                                Alterar imagem
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-7 mt-4 mt-md-0">
                                <div className="card border-0 bg-secondary rounded-5">
                                    <div className="card-body bg-secondary rounded-5 border-0 p-5">
                                        <h4>
                                            Banner do perfil
                                            <Button id="infoBannerCollection" type="button" className="link bg-transparent border-0">
                                                <i className="fa-sharp fa-solid fa-circle-info text-info fs-5 ms-2" />
                                            </Button>
                                        </h4>
                                        <PopoverInfo target="infoBannerCollection" limit="1" supported="JPG, PNG" />

                                        <BannerContent onClick={() => inputFileBanner.current.click()}>
                                            {bannerProfile.length > 0 && <img src={URL.createObjectURL(bannerProfile[0])} width={"100%"} className="banner-profile" />}

                                            {!bannerProfile.length && user?.profile_banner && <img src={user?.profile_banner} width={"100%"} className="banner-profile" />}

                                            {!bannerProfile.length && !user?.profile_banner && (
                                                <PlaceholderBanner>
                                                    <i className="bi bi-file-image" />
                                                </PlaceholderBanner>
                                            )}

                                            <input className="btn btn-primary " type="file" onChange={handleChangeImageBanner} ref={inputFileBanner} />

                                            {/* <button className="btn btn-primary teste" type="button">
                                                Alterar imagem
                                            </button> */}
                                        </BannerContent>

                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> Nome completo </label>
                                                    <Field className="form-control" name="name" validate={validateName} />
                                                    {errors.name && touched.name && <RequireMessage message={errors.name} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> Nome de usuário </label>
                                                    <Field className="form-control" name="username" />
                                                    {errors.username && touched.username && <RequireMessage message={errors.username} />}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> Data de nascimento </label>
                                                    <InputMask
                                                        className="form-control"
                                                        mask="99/99/9999"
                                                        name="birth_date"
                                                        type="text"
                                                        maskChar={null}
                                                        value={values.birth_date}
                                                        onChange={e => setFieldValue("birth_date", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="input-control mb-2">
                                            <label className="form-label"> Biografia </label>
                                            <Field as="textarea" className="form-control" name="biography" />
                                            {errors.biography && touched.biography && <RequireMessage />}
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> E-mail </label>
                                                    <Field className="form-control" name="email" />
                                                </div>
                                            </div>
                                        </div>

                                        <h5 className="mt-3">Links e Redes sociais</h5>

                                        <div className="input-control mb-2">
                                            <label className="form-label"> Seu site </label>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">
                                                    <i className="bi bi-globe" />
                                                </span>
                                                <Field className="form-control" name="site" type="url" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> Twitter </label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">
                                                            <i className="bi bi-twitter" />
                                                        </span>
                                                        <Field className="form-control" name="twitter" type="url" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="input-control mb-2">
                                                    <label className="form-label"> Discord </label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">
                                                            <i className="bi bi-discord" />
                                                        </span>
                                                        <Field className="form-control" name="discord" type="url" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 pt-4 border-top">
                                            <button className="btn btn-lg btn-primary w-100" type="submit">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </ProfileContent>
    );
};

export default FormCreate;
