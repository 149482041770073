import { createRef, useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import logo from 'assets/img/logo.png';
import ButtonConnect from '../ButtonConnect';
import { useApp } from 'web3/providers/AppContext';
import SearchModal from 'components/SearchModal/SearchModal';
import Autocomplete from 'components/Autocomplete/Autocomplete';

const headerRef = createRef();

const Navbar = ({ toggleTheme, theme }) => {
  const [opacity, setOpacity] = useState(1);
  const { wallet } = useApp();

  useEffect(() => {
    const headerHeight = headerRef.current.clientHeight;
    const range = 200;
    const offset = headerHeight / 2;

    const didScrollPage = (e) => {
      let calc = 1 - (window.scrollY - offset + range) / range;

      let status = 1;

      if (calc < 0) {
        status = 0;
      }

      setOpacity(status);
    };

    window.addEventListener('scroll', didScrollPage);

    return () => {
      window.removeEventListener('keydown', didScrollPage);
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top 
            ${theme === 'dark' ? 'navbar-dark' : 'navbar-light'} 
            ${
              opacity === 1
                ? 'navbar-bg'
                : theme === 'dark'
                ? 'navbar-bg-dark-50'
                : 'navbar-bg-light-50'
            }`}
      aria-label='navbar'
      ref={headerRef}
    >
      <div className='container'>
        <Link to='/' className='navbar-brand'>
          <img src={logo} alt='Logo Voluhpia' />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarsExample05'
          aria-controls='navbarsExample05'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon' />
        </button>

        <div className='collapse navbar-collapse' id='navbarsExample05'>
          <Autocomplete></Autocomplete>
          {/* <div className='ms-xl-5'>
            <form role='search' className='search-form'>
              <div className='input-group '>
                <input
                  type='search'
                  className='form-control form-control-custom rounded-pill border-0 text-center'
                  placeholder='Explorar'
                  aria-label='Search'
                  aria-describedby='Search'
                />
              </div>
            </form>
          </div> */}

          <ul className='navbar-nav ms-auto align-items-center'>
            <ul className='navbar-nav justify-content-right'>
              <li className='nav-item'>
                <NavLink to={'/'} className='nav-link' aria-current='page'>
                  Home
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to={'/explorer'} className='nav-link'>
                  Explorar
                </NavLink>
              </li>
              {/* <li className='nav-item'>
              <NavLink to={'/creators'} className='nav-link'>
                Criadores
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to={'/about'} className='nav-link'>
                Sobre
              </NavLink>
            </li> */}
            </ul>

            <li className='nav-item px-3'>
              <ButtonConnect />
            </li>

            {wallet ? (
              <li className='nav-item'>
                <span
                  className='nav-link py-0 px-3'
                  data-bs-toggle='offcanvas'
                  role='button'
                  data-bs-target='#sidebarAccount'
                  aria-controls='sidebarAccount'
                >
                  <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDBweCIgaGVpZ2h0PSI0MHB4IiB2aWV3Qm94PSIwIDAgODAgODAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgPGRlZnM+CiAgICA8bGluZWFyR3JhZGllbnQgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSIgaWQ9Ijc1NTE3MzMwNDIwMiI+CiAgICAgIDxzdG9wIHN0b3AtY29sb3I9InJnYigyMzQsIDAsIDI1NSkiIG9mZnNldD0iMCUiPjwvc3RvcD4KICAgICAgPHN0b3Agc3RvcC1jb2xvcj0icmdiKDI1NSwgMjM0LCAwKSIgb2Zmc2V0PSIxMDAlIj48L3N0b3A+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIGZpbGw9InVybCgjNzU1MTczMzA0MjAyKSIgeD0iMCIgeT0iMCIgd2lkdGg9IjgwIiBoZWlnaHQ9IjgwIj48L3JlY3Q+CiAgPC9nPgo8L3N2Zz4=" alt="" width="40" className="circle img-fluid" />
                </span>
              </li>
            ) : null}

            {/* <li className="nav-item">
                            <span className="nav-link px-3" role="button" onClick={toggleTheme}>
                                <span className="fs-4">
                                    { theme === 'dark'
                                        ? <i className="fa-solid fa-sun text-warning" />
                                        : <i className="fa-solid fa-moon text-primary" />
                                    }
                                </span>
                            </span>
                        </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
