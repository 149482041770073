import logo from "assets/img/logo.png";

import {Link} from "react-router-dom";

const LinkFooter = (props) => {
    return (
        <li className="nav-item mb-2">
            <Link target={props.target} to={ props.to } className="nav-link p-0">{ props.label }</Link>
        </li>
    )
}

const Footer = () => {
    return (
        <footer className="footer mt-auto pt-5 position-relative w-100 top-50">
            <div className="container">

                <div className="row row-footer gx-5 gy-5">

                    <div className="col-12 col-md-12 col-xl-4 mb-3 border-right">
                        <Link to="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none justify-content-center justify-content-xl-start">
                            <img src={logo} alt="Logo Voluhpia" />
                        </Link>
                        <p className="mt-4 text-center text-xl-start">
                        Viva sua vida alternativa! O primeiro metaverso gamer viva e ganhe, focado na experiência de jogadores e marcas.
                        </p>
                    </div>

                    <div className="col-12 col-xl-8 justify-content-center">
                        <div className="row justify-content-center justify-content-md-start text-center text-sm-start">
                            <div className="col-12 col-sm-6 col-xl-3 mb-3">
                                <h5>Marketplace</h5>
                                <ul className="nav flex-column">
                                    <LinkFooter to={"/explorer"} label="Explorar" />
                                    {/* <LinkFooter label="Populares" />
                                    <LinkFooter label="Leilões" />
                                    <LinkFooter label="Criadores" />
                                    <LinkFooter label="Coleções" /> */}
                                </ul>
                            </div>

                            <div className="col-12 col-sm-6 col-xl-3 mb-3">
                                <h5>Minha conta</h5>
                                <ul className="nav flex-column">
                                    <LinkFooter to={"/profile"} label="Meu perfil" />
                                    {/* <LinkFooter label="Perfil" />
                                    <LinkFooter label="Favoritos" />
                                    <LinkFooter label="Minhas coleções" />
                                    <LinkFooter label="Criar" />
                                    <LinkFooter label="Configurações" /> */}
                                </ul>
                            </div>

                            <div className="col-12 col-sm-6 col-xl-3 mb-3">
                                <h5>Ajuda</h5>
                                <ul className="nav flex-column">
                                    <LinkFooter label="Perguntas frequentes" />
                                    {/* <LinkFooter label="Parceiros" />
                                    <LinkFooter label="Sobre Taxas" />
                                    <LinkFooter label="Blog" /> */}
                                </ul>
                            </div>

                            <div className="col-12 col-sm-6 col-xl-3 mb-3">
                                <h5>Sobre</h5>
                                <ul className="nav flex-column">
                                    <LinkFooter target={'_blank'} to={'https://www.metaexp.io/'} label="Quem somos" />
                                    {/* <LinkFooter label="Time" /> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between py-4 mt-4 border-top">
                    <p>Copyright MobiUp © { new Date().getFullYear() }</p>
                    <p>Todos os direitos reservados</p>
                </div>
            </div>
        </footer>


    )
}

export default Footer
