import { createGlobalStyle } from "styled-components";

import theme from "./theme";

const GlobalStyle = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;0,700;0,800;1,400&display=swap');


    .mobi-sweet-alert {
        padding: 3em;
    }

    html {
        width: 100%;
        height: 100%
    }

    body {
        margin: 0;
        padding: 0;
        background: ${({ theme }) => theme.bg};
        color: ${({ theme }) => theme.color};
        font-family: 'Open Sans', sans-serif;
        overflow-x: hidden;
    }
  
  .wrapper {
        background: rgb(0,0,0);
        background: ${({ theme }) => theme.bg};
        color: ${({ theme }) => theme.color};
        font-family: 'Open Sans', sans-serif;
        overflow-x: hidden;
        padding-top: 93px;
  }

  #form-checkout {
    display: flex;
    flex-direction: column;
    max-width: 600px;
  }

  .circle{
    border-radius: 50%;
  }

  .radius-50{
    border-radius: 50px;
  }
  
  input:focus,
  input:active{
    box-shadow: none !important;
  }
  
  .form-control.form-control-custom {
    background-color:  ${({ theme }) => theme.inputBg};
    color: ${({ theme }) => theme.color};
  }
  
//#################### Font Awesome ####################
    .fa-2x-custom {
        font-size: 1.5em;
    }
//#################### Font Awesome ####################  
  
  
//#################### Colors ####################
  
  .bg-grey-300 {
    background-color: ${theme.colors.grey300};
  } 
   
  .bg-grey-800 {
    background-color: ${theme.colors.grey800};
  }
  
  .bg-grey-900 {
    background-color: ${theme.colors.grey900};
  }

  .bg-default{
    background-color: rgba(68,0,255,1);
  }

  .bg-secondary{
    background-color: rgba(19,9,51,1) !important;
  }
  
  .text-grey-800 {
    color: ${theme.colors.grey800};
  }
  
  .text-blue-200 {
    color: ${theme.colors.blue200};
  }
  
//#################### Colors ####################

  .form-control.rounded-start-pill {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px; 
  }
  
  .form-control.rounded-end-pill,
   .btn.rounded-end-pill{
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px; 
    z-index: 1;
  }
  
//#################### Header ####################
  
  .navbar-dark {
    border-bottom: 1px solid rgba(255,255,255,.2);
    padding: 20px 0;
    z-index: 2;
  }
  
  .navbar-light {
    border-bottom: 1px solid ${({ theme }) => theme.border};
    padding: 20px 0;
    z-index: 2;
  }
  
    .navbar-bg {
        background-color: ${({ theme }) => theme.bgdefault};
        color: ${({ theme }) => theme.color};
    }
  
    .navbar-bg-dark-50 {
        background-color: #0000006e;
        margin-top: 0;
        backdrop-filter: blur(20px);  
    }
    
    .navbar-bg-light-50 {
        background-color: #ededed6e;
        margin-top: 0;
        backdrop-filter: blur(20px);  
    }
    
    .navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
        border-bottom: 3px solid ${({ theme }) => theme.color};
    }
 
    .search-form {
        width: 230px;
    }
    
 
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 25px;
        padding-left: 25px;
    }
  }
  
   @media (max-width: 992px){
    .navbar-bg {
        margin-top: 0;
    }
   }
  
  
  @media (max-width: 991px) {
    .navbar-nav {
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
    }
  
    .navbar-expand-lg .navbar-nav .nav-link {
        font-size: 20px;
        padding-right: 25px;
        padding-left: 25px;
    }
    
    .search-form {
        width: 100%;
        margin: 40px auto 20px;
    }
    
    .header-action {
        border-radius: 25px;
    }
    
  }

  @media (max-width: 767px){
    .container-suggest{
      margin-top: 20px;
      width: 100%;
    }
  }
  


//#################### Header ####################

//#################### Hero Home ####################

.content-totals div > h1 {
  font-weight: 300;
  font-size: 1.5rem;
  margin-bottom: 0;
  color: #ffffff;
}

.content-totals div > p {
  margin-bottom: 0;
}

.content-totals div:nth-child(2) {
  border-right: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
  padding: 0 20px;
}


//#################### Hero Home ####################


//#################### Footer ####################
    
    .footer {
        background-color: rgba(20, 10, 52, .7);
        color: ${({ theme }) => theme.color};
    }
        
    @media (max-width: 1199px) {
        .row-footer .border-right {
            border-right: 0 !important
        }
    }
    
    .row-footer h5 {
        margin-bottom: 10px;
    }
    
    footer .nav.flex-column > li .nav-link {
      padding: 7px 0px;
      color: ${({ theme }) => theme.color}90;
      position: relative;
      font-size: 13px;
      font-weight: 400;
    }
    

//#################### Footer ####################

//#################### Social Link ####################

    .social-link {
      height: 50px;
      width: 50px;
      border-radius: 15px;
      background-color: ${theme.colors.default};
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .social-link svg {
      font-size: 20px;
      color: #000000;
    }
    
//#################### Social Link ####################

  
//#################### Buttons ####################
    
    .btn-connect {
        width: 170px;
        height: 52px;
        padding: 5px 15px;
        display: flex;
        align-items: center;
    }
    
    .btn-connect span {
        text-align: center;
        line-height: 20px;
    }
    
    .btn-connect svg {
        height: 1.3rem;
    }
    
    .btn-account {
        display: block;
        height: 30px;
        width: 30px;
        border-radius: 50px;
        background-color: #ffffff;
        border: 3px solid #000000;
        outline: 2px solid #ffffff;
        margin-bottom: 4px;
    }

// #################### Buttons ####################

// #################### NAV-TABS-HOME ####################
.contents .nav-tabs, 
.marketplace .nav-tabs {
  position: relative;
  border: none;
}

// #################### NAV-TABS-HOME ####################


//#################### Swiper ####################

.swiper-slide {
    height: auto;
}

.swiper-slide .card {
    background-color: rgba(19,9,51,1);
    border-radius: 20px;
}

.swiper-slide .card .icon-head-card {
    height: 1.4em;
}



//#################### Swiper ####################

//#################### Modal ####################

.modal-content {
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
}

.modal-header {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.modal-body {
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}

//#################### Modal ####################

    .h1-title {
      color: ${({ theme }) => theme.color};
      font-size: 2.725rem;
    }
        
      
    .modal-title {
        color: #333;
    }
    
    hr {
        margin: 0.5rem 0;
    }
    

    .input-search-icon {
        position: absolute;
        right: 0;
        top: 3px;
    }
    
    .border-custom {
        border-radius: 20px;
    }
    
    .mt-custom {
        margin-top: 0;
    }
    
    @media (max-width: 768px) {
    
        .hero-home .hero-image {
            height: 70vh;
        }
    
        .mt-custom {
            margin-top: 0px;
        }
    
        .border-end {
            border-right: 0;
        }
    }
    
    .btn-close {
        background-color: #e6e6e6;
        opacity: 1;
        border-radius: 50%;
        background-size: 0.8em;
    }

    .border-top {
        border-top: var(--bs-border-width) var(--bs-border-style) ${({ theme }) => theme.border}  !important;
    }   
    
    .border-bottom {
        border-bottom: var(--bs-border-width) var(--bs-border-style) rgba(255,255,255,.05) !important;
    } 
    
    .border-left {
        border-left: var(--bs-border-width) var(--bs-border-style) ${({ theme }) => theme.border}  !important;
    } 
    
    .border-right {
        border-right: var(--bs-border-width) var(--bs-border-style) ${({ theme }) => theme.border}  !important;
    } 
    
    .bg-primary-opacity {
        background-color: ${props => props.theme.primaryOpacity};
    }
    
    .breadcrumb a {
        color: #6c757d;
        text-decoration: none;
    }

    .breadcrumb-item.active{
      color: #fff;
    }
`;

export default GlobalStyle;
