import {useState} from "react";

import { ModalApp } from "components/Modal";
import { UniswapSwap } from "../UniswapSwap";

import { CardContent } from './styles';

import CRPLAY from 'assets/img/crplay.png';
import MATIC from 'assets/img/matic.png';

import { CheckoutPix } from "components/Checkout";
import { CheckoutCrypto } from "components/CheckoutCrypto";

export const CardCoin = () => {
    const [openPixModal, setOpenPixModal] = useState(false);
    const [openPixNFTModal, setOpenPixNFTModal] = useState(false);
    const [openMaticModal, setOpenMaticModal] = useState(false);

    const [showCheckout, setShowCheckout] = useState(false);
    const [actuallyLabel, setActuallyLabel] = useState('Comprar Nft');

    const nftTemp = {
        imageId: 'oGlyWp0tMp1Vfxed2DMZ'
    }

    const cryptoTemp = {
        imageId: Math.random().toString()
    }

    const handleOpenPixModal = () => {
        setOpenPixModal(true);
    }

    const handleOpenMaticModal = () => {
        setOpenMaticModal(true);
    }

    const handleOpenPixModalNFT = () => {
        setOpenPixNFTModal(true);
    }

    const handleCloseModals = () => {
        setOpenPixModal(false);
        setOpenMaticModal(false);
        setOpenPixNFTModal(false);
    }

    function handleOpenCheckout(){
        setShowCheckout(true)
    }

    function handleCloseCheckout(){
        setShowCheckout(false)
    }

    return (
        <CardContent>
            <div className="col-12">
                <div className="card">
                    <div className="card-image p-4">
                        <img src={CRPLAY} alt="crplay" className="img-fluid" />
                    </div>
                    <div className="p-3">
                        <div className="d-flex  justify-content-between align-items-center">
                            <div className="creator">
                                <h2 className="m-0 fs-4 fw-bold">
                                    CRPLAY
                                </h2>
                            </div>
                            {/* <div className="text-end">
                                <small>Preço</small>
                            </div> */}
                        </div>

                        <hr className="mt-3 mb-3"/>

                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-grid w-100">
                                <button className="btn btn-lg btn-primary w-100 rounded-pill d-none" onClick={() => handleOpenPixModal()}>
                                    Comprar com PIX <i className="fa-brands fa-pix"></i>
                                </button>
                                <button className="mt-2 btn btn-lg btn-secondary w-100 rounded-pill d-flex align-items-center justify-content-center" onClick={() => handleOpenMaticModal()}>
                                    Comprar com MATIC <img src={MATIC} alt="MATIC logo" className="ms-2 img-flui d-inline" width="22" />
                                </button>
                                <button className="mt-2 btn btn-lg btn-primary w-100 rounded-pill d-none" onClick={() => handleOpenPixModalNFT()}>
                                    Comprar NFT com PIX <i className="fa-brands fa-pix"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalApp
                isOpen={openPixModal}
                closeModal={handleCloseModals}
                label="Comprar CRPLAY com PIX"
                centered={true}
                size="sm"
            >
                {/* Conteúdo para o modal do PIX */}
                <CheckoutCrypto isOpen={showCheckout} closeModal={handleCloseCheckout} modalLabel={setActuallyLabel} crypto={cryptoTemp} />
            </ModalApp>

            <ModalApp
                isOpen={openMaticModal}
                closeModal={handleCloseModals}
                label="Trocar MATIC por CRPLAY"
                centered={true}
                size="sm"
            >
                {/* Conteúdo para o modal do MATIC */}
                <UniswapSwap modal={setOpenMaticModal} />
            </ModalApp>


            <ModalApp
                isOpen={openPixNFTModal}
                closeModal={handleCloseModals}
                label="Comprar NFT com PIX"
                centered={true}
                size="sm"
            >
                <CheckoutPix isOpen={showCheckout} closeModal={handleCloseCheckout} modalLabel={setActuallyLabel} nft={nftTemp} />
            </ModalApp>
        </CardContent>
    )
}
