import * as S from '../styles'
import { useEffect, useState,useRef } from 'react'
import {faTriangleExclamation,faClock,faCopy} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CopyToClipboard from 'react-copy-to-clipboard';
import {copyAddress} from "../../../utils/utils"
import { isInteger } from 'lodash';
import {checkPixStatus} from "../../../services/pix.service";
import { formatarValorReal } from '../../../utils/utils';
import {ErrorStep} from "./errorStep"

const formatTime = (time) => {
  let minutes = Math.floor(time / 60);
  let seconds = Math.floor(time - minutes * 60);

  if (minutes <= 10 ) minutes = '0' + minutes;
  if (seconds <= 10 ) seconds = '0' + seconds;
  return `${minutes}:${seconds}`;
}

export const  FifthStep = ({ invoice, rfqId, changeStep, nft, changeToFirstStep}) => {
  const [inputValue, setInputValue] = useState('');
  const [countDown, setCountDown] = useState(300);
  const [expire, setExpire] = useState(false);
  const timerId = useRef();

  useEffect(() => {
      timerId.current = setInterval( () => {
      setCountDown( (prev) =>  { 

        if(isInteger(prev/5)) {
          const checkStatus =  async () => {
              const response = await checkPixStatus({
                rfqId: rfqId.id,
                network: nft.network
              })
              
              if(response.rfq.status === "RFQ_STATUS_SETTLEMENT" || response.rfq.status === "RFQ_STATUS_FULFILLED") {
                changeStep();
              }
          };
          checkStatus();
        } 
        
        return prev - 1

      });
      
    }, 1000);
    
    return () => clearInterval(timerId.current);
  
  }, []);

  useEffect(() => {
    if(countDown <= 0){
      clearInterval(timerId.current);
      setExpire(true);
    }    
  }, [countDown]);

  if(expire == true) {  
    return(<ErrorStep errorMsg={'Qr Code Expirado!'} buttonLabel={'Refazer compra'} changeStep={changeToFirstStep} nft={nft}/>)
  }

  if(expire == false) {
    return(
      <S.CheckoutContainer>
        <S.paymentDetails>
        <h5>{formatarValorReal(rfqId?.quote?.result?.cash?.value)}</h5>
        <p><FontAwesomeIcon icon={faClock} size='sm' className='' />Sua compra expira em {formatTime(countDown)} segundos!</p>
        <div>
        <FontAwesomeIcon icon={faTriangleExclamation} size='lg' className='' />
       <span>Apenas o titular do CPF pode realizar o pagamento PIX.</span> 
        </div>
        </S.paymentDetails>
  
        <S.QrContainer>
          
         <p>Abra o aplicativo do seu banco de preferencia,clique em PIX e aponte a câmera para o QR code</p>
  
         <img src={`data:image/png;base64,${invoice.pixImage.imageBase64}`} alt="" />
  
         <span>Copie e cole o código QR</span>
  
         <CopyToClipboard text={invoice.pixImage.imageText}>
        <div  onClick={() => copyAddress(invoice.pixImage.imageText)} > <input type="text" value={invoice.pixImage.imageText} onChange={e => setInputValue(e.target.value)} /> <i><FontAwesomeIcon icon={faCopy} size='sm' className='' color='black' /></i></div>
        </CopyToClipboard>
        
        </S.QrContainer>
  
  
      <S.CheckoutFooter>
        <a href='https://itspay.io/'> <strong>Powered by</strong>  <img src="https://itsramp.io/itspay_assets/img/ItsPay.png?t=1683717564" alt="" /></a>
      </S.CheckoutFooter>
  
      </S.CheckoutContainer>
    )
  }  
}