import styled from 'styled-components';

export const CardContent = styled.div`
    .card {
        background-color: ${({theme}) => theme.bgsecondary};
        border-radius: 20px;
    }
    
    a {
        text-decoration: none;
        color: ${({theme}) => theme.color};
    }
    
    .data-supply {
        background-color: ${({theme}) => theme.bg};
        padding: 8px 15px;
        border-radius: 25px;
    }
    
    .card .icon-head-card {
        height: 1.4em;
    }
 
    
    .creator {
        align-items: center;
        display: flex;
        gap: 0.75em;
       
    }

    .card-body h2{
        overflow: hidden;
    }

    .card-body h2 a{
        width: 100%;
        white-space: nowrap;
        display: block;
        overflow: hidden;
    }
    
    .card-image  {
        border-radius: 10px;
        overflow: hidden;
    }
    
    .card-image img {
        transition: transform .5s ease;
        
        width: 100%;
        height: 300px;
        object-fit: cover;
    }

    .card-image:hover img {
      transform: scale(1.1);
    }
    
    .creator img {
        border-radius: 10px;
        object-fit: cover;
        width: 2.5em;
        height: 2.5em;
        border: 2px solid #ffffff;
    }
   
    .creator div  {
        display: grid;
        margin-bottom: 2px;
    }
    
    .creator span:first-child  {
        font-size: 12px;
    }
    
     .creator span:last-child  {
        font-size: 14px;
        font-weight: bold;
    }


`;
