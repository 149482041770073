import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import * as S from '../styles'

export const  ErrorStep = ({errorMsg,buttonLabel,changeStep,crypto}) => {

  const handleCloseModal = () => {
    localStorage.removeItem(crypto?.imageId) 
    changeStep()
  }

return(
    <S.CheckoutContainer>
      <S.AlertDetalis>
      <FontAwesomeIcon icon={faExclamationCircle} size='3x' className='error' />
      <h4>Ops!</h4>
      <p>{errorMsg}</p>
      </S.AlertDetalis>

      <S.ContinueButton onClick={handleCloseModal} >
        {buttonLabel}
      </S.ContinueButton>


    <S.CheckoutFooter>
      <a href='https://itspay.io/'> <strong>Powered by</strong>  <img src="https://itsramp.io/itspay_assets/img/ItsPay.png?t=1683717564" alt="" /></a>
    </S.CheckoutFooter>

    </S.CheckoutContainer>
  )

}