import styled from 'styled-components';

export const Content = styled.div `
  
    padding: 1em 0;
    
    .card {
        background-color: #333;
        border-radius: 20px;
    }

    .form-check-input {
        width: 1.4em;
        height: 1.4em;
        background-color: ${({theme}) => theme.bg};
    }

`;


