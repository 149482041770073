/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { Content } from './styles';
import { networkParams } from 'web3/networks/networks';
import { useCollection } from 'web3/providers/CollectionContext';
import { ListNFT } from 'components/ListNFT';
import { scrollToTop } from 'utils/utils';
import moment from 'moment';

import Banner from 'assets/img/banner-collection.png';

export const CollectionPage = () => {
  const [collection, setCollection] = useState({});
  const [nfts, setNfts] = useState([]);
  const { getNfts, getCollection } = useCollection();
  const { address } = useParams();

  console.log("COLLECTION PAGE -> ", collection);

  useEffect(() => {
    setCollection(getCollection(address));
    setNfts(getNfts(address));
    setTimeout(() => {
      console.log('executar o scroll to top!!!');
      scrollToTop();
      console.log("collection ref", collection);
    }, 200);
  }, [address, getCollection, getNfts]);

  const handleShareButtonClick = (title, text) => {
    if (navigator.share) {
      navigator.share({
        title: title,
        text: text,
        url: window.location.href
      })
      .then(() => console.log('Link compartilhado com sucesso!'))
      .catch((error) => console.error('Erro ao compartilhar o link:', error));
    } else {
      console.log('A API de compartilhamento não é suportada neste navegador.');
    }
  };

  const unixTimestamp = collection?.createdAt?.seconds + collection?.createdAt?.nanoseconds / 1e9;
  const formattedDate = moment.unix(unixTimestamp).format('MM/YYYY');

  //const formattedDate = new Date(collection?.createdAt * 1000).toLocaleDateString('pt-BR', { month: '2-digit', year: 'numeric' });

  return (
    <Content>
      <div className='banner-collection bg-light'>
        <img
          src={Banner}
          className='img-fluid'
        />
        <div className='container'>
          <div className='image-collection'>
            <img src={collection?.image} className='shadow-sm' alt='' />
          </div>
        </div>
      </div>
      <div className='container py-5'>
        <div className='d-flex justify-content-between'>
          <div className='mt-4'>
            <h1 className=''>{collection?.name}</h1>

            <span className='fs-5 fw-light'>
              Criado por{' '}
              <Link to='#' className='creator-link text-decoration-none fw-bold'>
                METAEXP
              </Link>{' '}
            </span>
          </div>

          <div className='icons'>
            {/* <Link to='#'>
              <i className='fa fa-globe' />
            </Link>
            <Link to='#'>
              <i className='fa-brands fa-discord' />
            </Link>
            <Link to='#'>
              <i className='fa-brands fa-twitter' />
            </Link> */}
            <Link to='#' onClick={() => handleShareButtonClick(collection?.name, collection?.description)}>
              <i className='fa fa-share-nodes' />
            </Link>
          </div>
        </div>

        <div className='row mt-3 fs-6'>
          <div className="col-6 col-lg-auto">
            <span>
              Itens <b>{nfts?.length}</b>
            </span>
          </div>
          <div className="col-6 col-lg-auto">
            <span>
              Criado em <b>{formattedDate}</b>{' '}
            </span>
          </div>
          <div className="col-6 col-lg-auto mt-4 mt-md-0">
            <span>
              <b>{collection?.category?.label}</b>{' '}
            </span>
          </div>
          <div className="col-6 col-lg-auto mt-4 mt-md-0">
            <span>{networkParams[collection?.chainId]?.network_name}</span>
          </div>
          <div className="col-6 col-lg-auto mt-4 mt-md-0">
            <span>
              <Link to={networkParams[collection?.chainId]?.address + collection?.contract} target='_blank' className='link_contract'>
                Ver na Blockchain
              </Link>
            </span>
          </div>
        </div>

        <div className='about my-4'>
          <p>{collection?.description}</p>
        </div>

        <hr />

        <ListNFT nfts={nfts} collection={collection} />
      </div>
    </Content>
  );
};
