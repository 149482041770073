import { useEffect, useState } from 'react';
import { CardContent } from './styles';
import Tilt from 'react-vanilla-tilt';
import { Link } from 'react-router-dom';
import { networkParams } from 'web3/networks/networks';
import { Badge } from 'reactstrap';
import { useApp } from 'web3/providers/AppContext';
import { getPrice, formatarValorReal } from "../../utils/utils";
import { useCollection } from 'web3/providers/CollectionContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const CardNFT = ({ nft, collection }) => {
  const { wallet } = useApp();
  const { collections, getUserNfts } = useCollection();
  const { id, image, chainId, metadado, contractAddress, walletAddress } = nft;
  const { name } = metadado ?? {};
  const [quote, setQuote] = useState();
  const [priceCollection, setPriceCollection] = useState(0);

  const crypto = networkParams[chainId]?.crypto;
  const getUpdatedPrice = () => {
    return new Promise((resolve) => {
      const responseMatic = getPrice('Matic');
      Promise.all([responseMatic]).then(function (result) {
        resolve(result[0]);
      });
    });
  };

  const fetchQuote = async () => {
    const updatedQuote = await getUpdatedPrice();
    const targetCollection = collections.find(collection => collection.contractAddress === nft?.contractAddress);
    if (targetCollection) {
      setQuote(updatedQuote * targetCollection?.price); 
      setPriceCollection(targetCollection?.price);
    } else {
      console.log(`Nenhuma coleção encontrada com o contrato de endereço ${nft?.contractAddress}`);
    }
  };

  useEffect(() => {
    fetchQuote();
    getUserNfts();
  }, []);

  return (
    <CardContent>
      <div className={`card ${walletAddress ? 'selled' : 'not-selled'}`}>
        <Tilt style={{ width: '100%' }}>
          <div className='card-image mx-3 mt-3'>
            <Link className='text-decoration-none text-white' to={'/collection/' + contractAddress + '/' + id}>
              {walletAddress ? (
                walletAddress === wallet ? (
                  <Badge color='success' className='myBadge'>
                    Meu NFT
                  </Badge>
                ) : (
                  <Badge color='danger' className='myBadge'>
                    Vendido
                  </Badge>
                )
              ) : null}
              <LazyLoadImage
                alt={"image"}
                src={collection?.thumbnail ? collection?.thumbnail : image}
                width="100px"
                height="300px"
                className='img-fluid  rounded-4 border-3 w-100'
                quality={50}
              />
            </Link>
          </div>
        </Tilt>

        <div className='card-body mx-2'>
          <h2 className='fs-5 fw-bold'>
            <Link className='text-decoration-none text-white' to={id}>
              {name}
            </Link>
          </h2>

          <div className='d-flex justify-content-between align-items-center'>
            <small>{chainId && networkParams[chainId].network_name}</small>
            <small className='fw-bold'>Preço</small>
          </div>

          <hr />

          <div className='d-flex my-2 justify-content-between align-items-center'>
            <div className='d-grid gap-2'>
              <Link
                to={'/collection/' + contractAddress + '/' + id}
                className='btn btn-primary'
              >
                Detalhes
              </Link>
            </div>
            <div className='text-end'>
              <h3 className='fs-5 mb-0'>
                {priceCollection} <small>{crypto}</small>
              </h3>
              <span className="fs-6 fw-bolder text-muted">{formatarValorReal(quote)}</span>
            </div>
          </div>
        </div>
      </div>
    </CardContent>
  );
};
