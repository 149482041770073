import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import theme from 'styles/theme';
import './ModalDefault.scss';

import Lottie from 'lottie-react';
import LoadingAnimation from 'assets/json/loading.json';
import SuccessAnimation from 'assets/json/success.json';
import ErrorAnimation from 'assets/json/error.json';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const ModalDefault = ({
  isOpen,
  title,
  description,
  type, //'loading' | 'success' | 'error'
  labelLeft = 'Adicionar na carteira',
  labelRight = 'Meu perfil',
  leftLink = '',
  rightLink = '/profile',
  leftHandle,
  rightHandle,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  if (!title) {
    if (type === 'error') {
      title = 'Oops!';
    } else if (type === 'success') {
      title = 'Parabéns';
    } else {
      title = 'Aguarde';
    }
  }

  if (!description) {
    if (type === 'error') {
      description = 'Algo de errado aconteceu. Tente novamente mais tarde';
    } else if (type === 'success') {
      description = 'Procedimento foi realizado com sucesso';
    } else {
      description = 'Os dados estão sendo processados';
    }
  }

  return (
    <Modal isOpen={show} centered={true} backdrop='static'>
      <ModalHeader
        style={{ background: theme.colors.background, color: 'white' }}
        className='border-0'
      >
        <p className='fw-bold fs-4 px-3 mb-0 title'>{title}</p>
      </ModalHeader>
      <ModalBody
        style={{ background: theme.colors.background }}
        className='pb-4 d-grid gap-3 rounded-bottom'
      >
        <Lottie
          animationData={
            type === 'loading'
              ? LoadingAnimation
              : type === 'success'
              ? SuccessAnimation
              : ErrorAnimation
          }
          style={{ height: 120 }}
          loop={type === 'loading' ? true : false}
        />
        <p className='description'>{description}</p>
        {type === 'success' ? (
          <div className='d-flex gap-3'>
            <Link
              to={leftLink}
              onClick={() => {
                setShow(false);
                if (leftHandle) {
                  leftHandle();
                }
              }}
              className='btn btn-primary w-50'
            >
              {labelLeft}
            </Link>
            <Link
              to={rightLink}
              onClick={() => {
                setShow(false);
                if (rightHandle) {
                  rightHandle();
                }
                window.location.href = rightLink;
              }}
              className='btn btn-success w-50'
            >
              {labelRight}
            </Link>
          </div>
        ) : null}

        {type === 'error' ? (
          <div className='d-flex gap-3 container-buttons-error'>
            <Link
              onClick={() => {
                setShow(false);
                if (leftHandle) {
                  leftHandle();
                }
              }}
              className='btn btn-secondary w-50'
            >
              <b>Entendi</b>
            </Link>
          </div>
        ) : null}
      </ModalBody>
    </Modal>
  );
};

export default ModalDefault;
