import React from 'react';

const CheckboxFilter = ({ label, isChecked, onChange }) => {
  const handleCheckboxChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <div className='form-check mb-1'>
      <input
        className='form-check-input'
        type='checkbox'
        value=''
        id='flexCheckDefault'
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <label
        className='form-check-label text-muted ms-2 mt-1'
        htmlFor='flexCheckDefault'
      >
        {label}
      </label>
    </div>
  );
};

export default CheckboxFilter;
