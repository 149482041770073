import Styled from 'styled-components'

export const ContentNav = Styled.div`
    .nav-link {
      color: ${({theme}) => theme.color};
      border: none;
    }

     .nav-link:focus, .nav-link:hover, .nav-link:active  {
        color: ${({theme}) => theme.color};
        border: none;
        border-bottom: 3px solid ${({theme}) => theme.color};
     }
         
    .nav-link, .nav-link:focus, .nav-link:hover {
        color: ${({theme}) => theme.color};
        border: none;
    }
    
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
      border-bottom: 3px solid ${({theme}) => theme.color};
    }
    
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
      background-color: transparent;
      border: 0;
      color: ${({theme}) => theme.color};
      border-bottom: 3px solid ${({theme}) => theme.color};
    }
`