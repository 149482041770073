import NewsLetter from 'components/NewsLetter';
import Collections from 'components/Collections';
import HeroHome from 'components/HeroHome';
import Coins from 'components/Coins';
import Create from 'components/Create';
export const Home = () => {
  console.log("ENVIROMENT ", process.env.REACT_APP_ENVIRONMENT);
  return (
    <>
      <HeroHome />
      <Collections />
      <Coins />
      {/* <Create /> */}
      <NewsLetter />
    </>
  );
};
