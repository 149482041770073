import {
    doc,
    getDoc,
    setDoc,
    collection,
    getDocs,
    query,
    where,
    updateDoc
} from "firebase/firestore";
import { db } from "../firebaseConfig";

export const get = async (collectionName, data) => {
    try {
        var docRef;
        if (collectionName == 'categories') {
            docRef = doc(db, collectionName, data);
        } else {
            docRef = doc(db, process.env.REACT_APP_ENVIRONMENT + "_" + collectionName, data);
        }

        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        }

        return false
    } catch (e) {
        console.log('error get()', e)
        return false
    }
}

export const getAll = async (collectionName) => {
    try {

        const data = []

        var docRef;
        if (collectionName == 'categories') {
            docRef = collection(db, collectionName);
        } else {
            docRef = collection(db, process.env.REACT_APP_ENVIRONMENT + "_" + collectionName);
        }

        const docSnap = await getDocs(docRef);

        console.log(process.env.REACT_APP_ENVIRONMENT + "_" + collectionName);
        console.log(docSnap);

        docSnap.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
        });

        return data
    } catch (e) {
        console.log('error get()', e)
        return false
    }
}

export const create = async (collectionName, data) => {
    try {
        if (collectionName == 'categories') {
            await setDoc(doc(db, collectionName), data);
        } else {
            await setDoc(doc(db, process.env.REACT_APP_ENVIRONMENT + "_" + collectionName), data);
        }

    } catch (e) {
        console.log('error create()', e)
        return false
    }
}

export const createUser = async (collectionName, data) => {
    try {
        if (collectionName == 'categories') {
            await setDoc(doc(db, collectionName, data.wallet), data);
        } else {
            await setDoc(doc(db, process.env.REACT_APP_ENVIRONMENT + "_" + collectionName, data.wallet), data);
        }

        return true
    } catch (e) {
        console.log('error createUser()', e)
        return false
    }
}

export const update = async (collectionName, data) => {
    try {
        var collection;
        if (collectionName == 'categories') {
            collection = doc(db, collectionName, data.id);
        } else {
            collection = doc(db, process.env.REACT_APP_ENVIRONMENT + "_" + collectionName, data.id);
        }

        delete data.id;
        await updateDoc(collection, data);

        return true
    } catch (e) {
        console.log('error update()', e)
        return false
    }
}

export const updateUser = async (collectionName, data) => {
    try {
        var collection;
        if (collectionName == 'categories') {
            collection = doc(db, collectionName, data.wallet);
        } else {
            collection = doc(db, process.env.REACT_APP_ENVIRONMENT + "_" + collectionName, data.wallet);
        }

        delete data.id;
        await updateDoc(collection, data);

        return true
    } catch (e) {
        console.log('error updateUser()', e)
        return false
    }
}

export const queryByField = async (collectionName, fieldName, fieldValue) => {
    try {
        var queryRef;
        if (collectionName == 'categories') {
            queryRef = collection(db, collectionName);
        } else {
            queryRef = collection(db, process.env.REACT_APP_ENVIRONMENT + "_" + collectionName);
        }

        const q = query(queryRef, where(fieldName, "==", fieldValue));

        const querySnapshot = await getDocs(q);

        const results = [];

        querySnapshot.forEach((doc) => {
            results.push({ id: doc.id, ...doc.data() });
        });
        // console.log(`Found ${results.length} documents in collection ${collectionName} where ${fieldName} = ${fieldValue}.`);
        return results;
    } catch (error) {
        console.error(`Error querying documents in collection ${collectionName} where ${fieldName} = ${fieldValue}: ${error}`);
        return null;
    }
}

export const queryByRange = async (collectionName, fieldName, startValue, endValue) => {
    try {
        var queryRef;
        if (collectionName == 'categories') {
            queryRef = collection(db, collectionName);
        } else {
            queryRef = collection(db, process.env.REACT_APP_ENVIRONMENT + "_" + collectionName);
        }

        const q = query(queryRef, where(fieldName, ">=", startValue), where(fieldName, "<=", endValue));

        const querySnapshot = await getDocs(q);

        const results = [];

        querySnapshot.forEach((doc) => {
            results.push({ id: doc.id, ...doc.data() });
        });
        //  console.log(`Found ${results.length} documents in collection ${collectionName} where ${fieldName} is between ${startValue} and ${endValue}.`);
        return results;
    } catch (error) {
        console.error(`Error querying documents in collection ${collectionName} where ${fieldName} is between ${startValue} and ${endValue}: ${error}`);
        return null;
    }
}